export enum PostMessageTypeEnum {
  URL = "url",
  ROUTE = "route",
  SHARE_INFO = "share_info",
  SUBSCRIPTION_INFO = "subscription_info",
  LETS_GO_COUNT = "letsGoCount",
  SHARE_COUNT = "shareCount",
  ROUT_HANDLER_COUNT = "routHandlerCount",
  NO_GEOLOCATION_ALERT = "noGeolocationAlert",
  MANAGE_SUBSCRIPTION = "manageSubscription"
}
