import styled from "styled-components";
import GreenMarker from "../assets/images/green-marker.svg";
import Building from "../assets/images/building-icon.svg";
import Buildings from "../assets/images/buildings-green.svg";
import ArrowRight from "../assets/images/arrow-right.svg";
import WorkTime from "../assets/images/work-time.svg";
import Phone from "../assets/images/phone.svg";
import Internet from "../assets/images/internet.svg";
import ArrowDown from "../assets/images/arrow-down.svg";
import Share from "../assets/images/share.svg";
import Button from "../assets/images/icon-button.svg";
import Entrance from "../assets/images/entrance.svg";
import Facebook from "../assets/images/facebook.svg";
import Instagram from "../assets/images/instagram.svg";

export const Wrapper = styled.div`
  flex-direction: column;
  background: #ffffff;
  justify-content: flex-start;
  bottom: 0;
  position: absolute;
  width: 100%;
  border-radius: 20px 20px 0px 0px;
  transition-property: height;
  transition-duration: 0.3s;
  z-index: 60;
`;

export const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: rgba(33, 33, 33, 0.08);
`;

export const UpperInfo = styled.div`
  display: flex;
  margin: 9px 24px 11px 24px;
`;

export const BottomInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: 18px 24px;
`;

export const PlaceTitle = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  margin-bottom: 1px;
  color: rgba(0, 0, 0, 0.87);
  width: 250px;
`;

export const PlaceInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const PlaceInfo = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
`;

export const BottomText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  width: 68%;
  margin-right: 11px;
`;

export const GreenMarkerIcon = styled.p`
  background-image: url(${GreenMarker});
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 16px;
`;

export const BuildingIcon = styled.p`
  background-image: url(${Building});
  height: 24px;
  width: 24px;
  margin-right: 16px;
`;

export const BuildingsIcon = styled.p`
  background-image: url(${Buildings});
  height: 19px;
  width: 22px;
  margin-right: 16px;
  margin-left: 27px;
  margin-bottom: 7%;
`;

export const ArrowRightIcon = styled.p`
  background-image: url(${ArrowRight});
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 24px;
`;

export const WorkTimeIcon = styled.p`
  background-image: url(${WorkTime});
  height: 24px;
  width: 24px;
  margin-right: 16px;
`;

export const PhoneIcon = styled.p`
  background-image: url(${Phone});
  height: 24px;
  width: 24px;
  margin-right: 16px;
`;

export const InternetIcon = styled.p`
  background-image: url(${Internet});
  height: 24px;
  width: 24px;
  margin-right: 16px;
`;

export const EntranceIcon = styled.p`
  background-image: url(${Entrance});
  height: 24px;
  width: 24px;
  margin-right: 16px;
`;

export const ArrowDownIcon = styled.p`
  background-image: url(${ArrowDown});
  background-repeat: no-repeat;
  width: 14px;
  height: 12px;
  margin-right: 28px;
`;

export const ShareIcon = styled.p`
  background-image: url(${Share});
  height: 18px;
  width: 18px;
`;

export const InstagramIcon = styled.p`
  background-image: url(${Instagram});
  height: 66px;
  width: 66px;
  margin: 4px;
`;

export const FacebookIcon = styled.p`
  background-image: url(${Facebook});
  height: 66px;
  width: 66px;
  margin: 4px;
`;

export const SocialNetworkIconsContainer = styled.div`
  display: flex;
`;

export const IconButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const IconButton = styled.p`
  background-image: url(${Button});
  width: 70px;
  height: 70px;
  display: flex;
  position: absolute;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.08));
  margin-right: 13px;
`;

export const SwipeIndicator = styled.div`
  width: 36px;
  min-height: 4px;
  border-radius: 4px;
  background: #dbdbdb;
  margin: 8px auto;
`;

export const AdditionalInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
`;

export const Title = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.6);
  margin: 16px;
`;

export const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
`;
export const AllBranchesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 2px rgba(33, 33, 33, 0.08);
`;
export const BranchesTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin: 6px 16px 11px 16px;
`;
export const BranchesCount = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CategoriesItem = styled.div`
  background: rgba(33, 33, 33, 0.08);
  border-radius: 16px;
  height: 32px;
  margin-right: 8px;
  margin-bottom: 8px;
`;

export const CategoriesItemText = styled.div`
  font-family: Intel;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  margin: 6px 12px;
`;

export const CategoriesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 16px 100px 16px;
`;
