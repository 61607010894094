import mapglTypes from "@2gis/mapgl/types/index";
import { API } from "api/index";
import { makeObservable, observable } from "mobx";
import { RootStore } from "./RootStore";
import { Directions } from "@2gis/mapgl-directions";
var parse = require("wellknown");

export class DirectionsStore {
  rootStore: RootStore;
  api: API;

  @observable directionsAPI: Directions | null = null;

  constructor(rootStore: RootStore, api: API) {
    this.rootStore = rootStore;
    this.api = api;

    makeObservable(this);
  }

  loadDirections(mapgl: mapglTypes.Map) {
    this.directionsAPI = new Directions(mapgl, {
      directionsApiKey: process.env.REACT_APP_SEARCH_KEY as string,
    });
  }

  getDirection(startPoint: number[], endPoint: number[], type: string) {
    return this.api.directions.getDirections(startPoint, endPoint, type);
  }

  buildDirection(
    startPoint: number[],
    endPoint: number[],
    type: string,
    color: string
  ): Promise<any> {
    return this.getDirection(startPoint, endPoint, type).then((res) => {
      const parsedDirection = parse(res.data[0].route);
      if (parsedDirection) {
        this.rootStore.mapglStore.buildDirection(
          parsedDirection.coordinates,
          color
        );
      }
      return res;
    });
  }

  destroyDirection() {
    this.rootStore.mapglStore.destroyDirection();
  }
}
