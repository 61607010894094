import { DirectionsStore } from "./DirectionsStore";
import { SearchStore } from "./SearchStore";
import { MapglStore } from "./MapglStore";
import { API } from "api";
import {LocalStorageStore} from "./LocalStorageStore";

export class RootStore {
  api: API;
  mapglStore: MapglStore;
  searchStore: SearchStore;
  directionsStore: DirectionsStore;
  localStorageStore: LocalStorageStore;

  constructor(api: API) {
    this.api = api;
    this.mapglStore = new MapglStore(this);
    this.searchStore = new SearchStore(this, api);
    this.directionsStore = new DirectionsStore(this, api);
    this.localStorageStore = new LocalStorageStore(this);
  }
}
