import styled from "styled-components";
import BackArrow from "assets/images/back-arrow.svg";
import Logo from "assets/images/logo.svg";
import SubList from "assets/images/sub-list-item.svg";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { SendData } from "utils/SendData";
import { PostMessageTypeEnum } from "enums/PostMessageType.enum";
import { useLocalStorageStore } from "hooks/store";

const SubscriptionPage = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #ffffff;
  padding: 0 16px 0 16px;
`;

const BackArrowWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 16px;
`;

const BackArrowIcon = styled.div`
  background-image: url(${BackArrow});
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
`;

const LogoWrapper = styled.div`
  width: 100%;
  margin-bottom: 27px;
  display: flex;
  justify-content: center;
`;

const LogoIcon = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
`;

const Header = styled.h2`
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
`;

const Description = styled.h2`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
`;

const SubscriptionItemWrapper = styled.div`
  width: 100%;
  padding: 24px 16px 24px 16px;
  border-radius: 5px;
  border: 1px solid #23b064;
  margin-bottom: 16px;
`;

const SubscriptionItemHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubscriptionType = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const SubscriptionPrice = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.15px;
`;

const PriceListItem = styled.div`
  width: 100%;
  margin-bottom: 4px;
  display: flex;
`;

const PriceListText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const SubListIcon = styled.div`
  background-image: url(${SubList});
  background-repeat: no-repeat;
  width: 20px;
  height: 15px;
  margin-top: 4px;
  margin-right: 4px;
`;

const Info = styled.div`
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 50px;
  margin-bottom: 16px;
`;

const SubscribeButton = styled.button`
  background: #23b064;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  width: 90%;
  margin-top: 16px;
`;

const RulesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
`;

const RulesText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #23b064;
`;

export const Subscription = () => {
  const [activePlan, setActivePlan] = useState(1);
  const history = useHistory();
  const localStorageStore = useLocalStorageStore();

  const [subStatus, setSubStatus] = useState<string | null>(null);
  useEffect(() => {
    if (
      localStorageStore.subType === "premium" ||
      localStorageStore.subType === "basic"
    ) {
      setSubStatus("active");
    } else {
      setSubStatus("inactive");
    }
  }, [localStorageStore.subType]);

  const checkUserData = (event: any) => {
    if (event.data) {
      if (
        event.data === "trial" ||
        event.data === "basic" ||
        event.data === "premium" ||
        event.data === "inactive"
      ) {
        localStorageStore.setSubType(event);
      }
      if (typeof event.data === "object") {
        if (event.data.type === "counters") {
          localStorageStore.setCounters(event);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", checkUserData);
    return () => window.removeEventListener("message", checkUserData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clickHandler = (e: any) => {
    e.preventDefault();
    if (subStatus === "active") {
      SendData({
        type: PostMessageTypeEnum.MANAGE_SUBSCRIPTION,
        data: `${activePlan}`,
      });
    } else {
      SendData({
        type: PostMessageTypeEnum.SUBSCRIPTION_INFO,
        data: `${activePlan}`,
      });
    }
  }

  return (
    <SubscriptionPage>
      <BackArrowWrapper>
        <BackArrowIcon
          onClick={() => {
            history.push("/settings");
          }}
        />
      </BackArrowWrapper>
      <LogoWrapper>
        <LogoIcon />
      </LogoWrapper>
      <Header>Subscription Plans </Header>
      <Description>Choose your plan that’s right for you</Description>
      <SubscriptionItemWrapper
        style={{
          backgroundColor:
            activePlan === 0 ? "rgba(0, 161, 84, 0.08)" : "#FFFFFF",
        }}
        onClick={() => setActivePlan(0)}
      >
        <SubscriptionItemHeaderWrapper
          style={{ marginBottom: activePlan === 0 ? "24px" : 0 }}
        >
          <SubscriptionType>Basic</SubscriptionType>
          <SubscriptionPrice>€5/month</SubscriptionPrice>
        </SubscriptionItemHeaderWrapper>
        {activePlan === 0 && (
          <>
            <PriceListItem>
              <SubListIcon />
              <PriceListText>100 navigator launches</PriceListText>
            </PriceListItem>
            <PriceListItem>
              <SubListIcon />
              <PriceListText>
                Share a location 100 times using GoogleMaps links
              </PriceListText>
            </PriceListItem>
            <PriceListItem>
              <SubListIcon />
              <PriceListText>
                100 times location tracking along the route
              </PriceListText>
            </PriceListItem>

            <SubscribeButton
              onClick={clickHandler}
            >
              {subStatus === "active" ? "Manage subscription" : "Subscribe"}
            </SubscribeButton>
          </>
        )}
      </SubscriptionItemWrapper>

      <SubscriptionItemWrapper
        style={{
          backgroundColor:
            activePlan === 1 ? "rgba(0, 161, 84, 0.08)" : "#FFFFFF",
        }}
        onClick={() => setActivePlan(1)}
      >
        <SubscriptionItemHeaderWrapper
          style={{ marginBottom: activePlan === 1 ? "24px" : 0 }}
        >
          <SubscriptionType>Premium</SubscriptionType>
          <SubscriptionPrice>€10/month</SubscriptionPrice>
        </SubscriptionItemHeaderWrapper>
        {activePlan === 1 && (
          <>
            <PriceListItem>
              <SubListIcon />
              <PriceListText>Unlimited navigator launches</PriceListText>
            </PriceListItem>
            <PriceListItem>
              <SubListIcon />
              <PriceListText>
                Unlimited number of times share a place with GoogleMaps links
              </PriceListText>
            </PriceListItem>
            <PriceListItem>
              <SubListIcon />
              <PriceListText>
                Unlimited location tracking along route
              </PriceListText>
            </PriceListItem>

            <SubscribeButton
              onClick={clickHandler}
            >
              {subStatus === "active" ? "Manage subscription" : "Subscribe"}
            </SubscribeButton>
          </>
        )}
      </SubscriptionItemWrapper>
      <Info>Can be cancelled at any time</Info>
      <RulesWrapper>
        <RulesText>Terms of Use </RulesText>
        <RulesText>Privacy Policy</RulesText>
      </RulesWrapper>
    </SubscriptionPage>
  );
};
