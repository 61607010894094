export enum ContactTypes {
  Email = "email",
  Website = "website",
  Phone = "phone",
  Fax = "fax",
  Icq = "icq",
  Jabber = "jabber",
  Skype = "skype",
  Vkontakte = "vkontakte",
  Twitter = "twitter",
  Instagram = "instagram",
  Facebook = "facebook",
  Pobox = "pobox",
  Youtube = "youtube",
  Odnoklassniki = "odnoklassniki",
  Googleplus = "googleplus",
  Linkedin = "linkedin",
  Pinterest = "pinterest",
  Whatsapp = "whatsapp",
  Telegram = "telegram",
  Viber = "viber",
}
