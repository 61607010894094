import axios, { AxiosInstance } from "axios";
import DirectionsAPI from "./directions";
import SearchAPI from "./search";

export const getClient = (): AxiosInstance => {
  return axios.create({
    baseURL: "https://catalog.api.2gis.com/",
    timeout: 3000,
    headers: { "Content-Type": "application/json", Accept: "application/json" },
  });
};

export interface API {
  search: SearchAPI;
  directions: DirectionsAPI;
}

const makeApi = (client: AxiosInstance): API => {
  return {
    search: new SearchAPI(client),
    directions: new DirectionsAPI(client),
  };
};
export default makeApi;
