import { FC } from "react";
import styled from "styled-components";
import ArrowRight from "assets/images/arrow-right.svg";

export const SubscriptionButton = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 16px;
  width: 93%;
  margin: 8px 0;
`;

export const SubscriptionButtonTextContainer = styled.div`
  margin: 13px 0 13px 13px;
`;

export const SubscriptionButtonText = styled.div`
  font-family: "Intel";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

const ActiveIndicator = styled.div`
  font-family: "Intel";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #ff5932;
`;

const ArrowRightIcon = styled.p`
  background-image: url(${ArrowRight});
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 20px;
`;

interface SettingsButtonProps {
  isSubscriptionButton?: boolean;
  handleClick: () => void;
  subStatus?: string | null;
}

export const SettingsButton: FC<SettingsButtonProps> = ({
  isSubscriptionButton = false,
  children,
  handleClick,
  subStatus = null,
}) => {
  return (
    <SubscriptionButton onClick={handleClick}>
      <SubscriptionButtonTextContainer>
        <SubscriptionButtonText>{children}</SubscriptionButtonText>
        {isSubscriptionButton ? (
          <ActiveIndicator>{subStatus}</ActiveIndicator>
        ) : null}
      </SubscriptionButtonTextContainer>
      <ArrowRightIcon />
    </SubscriptionButton>
  );
};
