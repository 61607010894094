/* eslint-disable  */
import Burger from "assets/images/burger.svg";
import Search from "assets/images/search.svg";
import BackArrow from "assets/images/back-arrow.svg";
import Cancel from "assets/images/cancel.svg";
import { useState } from "react";
import { SearchStore } from "stores/SearchStore";
import styled from "styled-components";
import { MapglStore } from "stores/MapglStore";
import { ResultItem } from "api/interfaces/search.interface";
import mapglTypes from "@2gis/mapgl/types/index";
import { useHistory } from "react-router";
import { toJS } from "mobx";

const SearchInputWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 95%;
  height: 48px;
  background: #ffffff;
  border-radius: 30px;
  display: flex;
  margin: 0 auto;
  margin-top: 50px;
  left: 0;
  right: 0;
`;

const BurgerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 40px;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 20px;
  margin: 4px;
`;

const BurgerIcon = styled.div`
  background-image: url(${Burger});
  width: 18px;
  height: 12px;
`;

const SearchIcon = styled.div`
  background-image: url(${Search});
  width: 18px;
  height: 18px;
  margin-left: 7px;
  margin-top: 15px;
`;

const Input = styled.input`
  height: 24px;
  width: 190px;
  margin-left: 27px;
  margin-top: 10px;
  border: 0;
`;

const BackArrowIcon = styled.div`
  background-image: url(${BackArrow});
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-left: 7px;
  margin-top: 15px;
`;

const CancelIcon = styled.div`
  background-image: url(${Cancel});
  width: 14px;
  height: 14px;
  margin-left: 7px;
  margin-top: 17px;
`;

interface SeacrhInputProps {
  searchStore: SearchStore;
  mapglStore: MapglStore;
  setOpenObjectCard: (value: boolean) => void;
  setIsEnterPressed: (value: boolean) => void;
  setIsResultsOpen: (value: boolean) => void;
  searchInputRef: React.RefObject<HTMLInputElement>;
  destroyAll: () => void;
  setIsMarkerCentred: (val: boolean) => void;
  isDirectionCard: boolean;
}

export const SearchInput = ({
  searchStore,
  mapglStore,
  searchInputRef,
  setOpenObjectCard,
  setIsEnterPressed,
  setIsResultsOpen,
  destroyAll,
  setIsMarkerCentred,
  isDirectionCard,
}: SeacrhInputProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const history = useHistory();

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (searchInputRef.current?.value) {
      searchInputRef.current && setInputValue(searchInputRef.current?.value);
      searchStore.setSearchValue(searchInputRef.current?.value);

      const viewPoints = mapglStore.mapgl?.getBounds();

      const res = await searchStore.getSearchResultsByQuery(
        searchInputRef.current?.value,
        viewPoints?.northEast as [number, number],
        viewPoints?.southWest as [number, number]
      );
      const suggestions = await searchStore.getSuggestions(
        event.target.value,
        viewPoints?.northEast as [number, number],
        viewPoints?.southWest as [number, number]
      );

      if (!res.length) {
        const firstSuggestionName =
          suggestions[0]?.name || suggestions[0]?.full_name;

        searchStore.getSearchResultsByQuery(
          firstSuggestionName,
          viewPoints?.northEast as [number, number],
          viewPoints?.southWest as [number, number]
        );
      }
    } else {
      setInputValue("");
    }
  };

  const handleEnter = async () => {
    if (searchInputRef?.current?.value) {
      searchInputRef.current && setInputValue(searchInputRef.current?.value);
      searchStore.setSearchValue(searchInputRef.current?.value);

      const viewPoints = mapglStore.mapgl?.getBounds();

      let res = await searchStore.getSearchResultsByQuery(
        searchInputRef.current?.value,
        viewPoints?.northEast as [number, number],
        viewPoints?.southWest as [number, number]
      );
      if (!res.length) {
        const suggestions = await searchStore.getSuggestions(
          searchInputRef.current?.value,
          viewPoints?.northEast as [number, number],
          viewPoints?.southWest as [number, number]
        );
        const firstSuggestionName =
          suggestions[0]?.name || suggestions[0].full_name;

        searchInputRef.current.value = firstSuggestionName;

        res = await searchStore.getSearchResultsByQuery(
          firstSuggestionName,
          viewPoints?.northEast as [number, number],
          viewPoints?.southWest as [number, number]
        );
      }

      res.map((item: any) => {
        if (item.point) {
          const marker = mapglStore.buildMarker(
            [item.point.lon, item.point.lat],
            false,
            false
          );
          marker?.on("click", () => {
            createMarkerEvent(marker, item);
          });
        }
      });
    }
    setIsResultsOpen(false);
  };

  const createMarkerEvent = (marker: mapglTypes.Marker, item: ResultItem) => {
    mapglStore.markers.map((marker: mapglTypes.Marker) => {
      marker.setIcon({ icon: "./marker.svg" });
    });
    mapglStore.setCurrentMarker(marker);
    marker.setIcon({ icon: "./red-marker.svg" });

    searchStore.getObjectById(item.id).then((res: any) => {
      searchStore.setCurrentObject(res.result?.items[0]);
    });

    setOpenObjectCard(true);
    mapglStore.setCurrentMarkerCoords([item.point.lon, item.point.lat]);
    mapglStore.setCenter([item.point.lon, item.point.lat]);
    history.push("/pin");
  };

  return (
    <SearchInputWrapper
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          history.push("/results");
          if (searchInputRef.current?.value) {
            setIsMarkerCentred(false);
            destroyAll();
            handleEnter();
          }
        } else {
          setIsEnterPressed(false);
        }
      }}
      style={{
        justifyContent: inputValue ? "space-around" : "",
      }}
      onClick={() => {
        searchInputRef.current && searchInputRef.current.focus();
      }}
    >
      {console.log(inputValue)}
      {!inputValue && (
        <BurgerWrapper
          onClick={() => {
            history.push("/settings");
          }}
        >
          <BurgerIcon></BurgerIcon>
        </BurgerWrapper>
      )}
      {!inputValue && <SearchIcon></SearchIcon>}
      <div style={{ display: "flex" }}>
        {inputValue && (
          <BackArrowIcon
            onClick={() => {
              if (searchInputRef?.current?.value && searchStore.searchValue) {
                searchInputRef.current.value = searchStore.searchValue;
              }
              setOpenObjectCard(false);
            }}
          />
        )}
        <Input
          placeholder="Search"
          onChange={handleChange}
          ref={searchInputRef}
          onFocus={() => {
            setIsInputFocused(true);
            setIsResultsOpen(true);
            setOpenObjectCard(false);
            setIsEnterPressed(false);
            destroyAll();
          }}
          style={{
            marginLeft: inputValue ? "24px" : "27px",
          }}
          onBlur={() => setIsInputFocused(false)}
        ></Input>
      </div>
      {inputValue && (
        <CancelIcon
          onClick={() => {
            if (searchInputRef.current?.value) {
              searchInputRef.current.value = "";
              destroyAll();
              setOpenObjectCard(false);
              setIsResultsOpen(false);
              setInputValue("");
            }
          }}
        />
      )}
    </SearchInputWrapper>
  );
};
