export const Places = [
  { title: "Company", type: "branch" },
  { title: "Building", type: "building" },
  { title: "Street", type: "street" },
  { title: "Parking", type: "parking" },
  { title: "Station", type: "station" },
  { title: "Attraction", type: "attraction" },
  { title: "Crossroad", type: "crossroad" },
  { title: "Gate", type: "gate" },
  { title: "Road", type: "road" },
  { title: "Route", type: "route" },
  { title: "Administrative Division", type: "adm_div" },
  { title: "City", type: "adm_div.city" },
  { title: "Country", type: "adm_div.country" },
  { title: "District Area", type: "adm_div.district_area" },
  { title: "District", type: "adm_div.district" },
  { title: "Division", type: "adm_div.division" },
  { title: "Living Area", type: "adm_div.living_area" },
  { title: "Place", type: "adm_div.place" },
  { title: "Region", type: "adm_div.region" },
  { title: "Settlement", type: "adm_div.settlement" },
  { title: "Coordinates", type: "coordinates" },
];
