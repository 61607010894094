import BaseAPI from "./base";

export default class DirectionsAPI extends BaseAPI {
  async getDirections(
    startPoint: number[],
    endPoint: number[],
    type: string
  ): Promise<any> {
    const options = {
      points: [
        {
          lon1: startPoint[0],
          lat1: startPoint[1],
          lon2: endPoint[0],
          lat2: endPoint[1],
        },
      ],
      type,
      output: "full",
    };
    const res = await this.axios.post(
      `get_pairs/1.0/car?key=${process.env.REACT_APP_SEARCH_KEY}`,
      options
    );

    return res;
  }
}
