import { API } from "api/index";
import { action, makeObservable, observable, runInAction } from "mobx";
import { RootStore } from "./RootStore";
import { ResultItem, SearchResponse } from "api/interfaces/search.interface";

export class SearchStore {
  rootStore: RootStore;
  api: API;
  @observable searchResults: ResultItem[] = [];
  @observable currentObject: ResultItem | null = null;
  prevSearchResults: ResultItem[] = [];
  prevObject: ResultItem | null = null;
  prevSuggestionsResults: any[] = [];
  //TODO: Replace any with the type
  @observable branchesInBuilding: any | null = null;
  @observable branchesInOrganization: SearchResponse | null = null;
  @observable suggestionsResults: any[] = [];
  searchValue: string | null = null;
  reqParams: any;

  constructor(rootStore: RootStore, api: API) {
    this.rootStore = rootStore;
    this.api = api;

    makeObservable(this);
  }

  destroyPreviousResults() {
    this.prevSearchResults = [];
    this.prevObject = null;
    this.prevSuggestionsResults = [];
  }
  setReqParams(reqParams: any): any {
    this.reqParams = reqParams;
    return this.reqParams;
  }

  resetReqParams(): void {
    this.reqParams = [];
  }

  setSearchValue(value: string) {
    this.searchValue = value;
  }

  @action
  async getSearchResults(
    idType: string,
    id: number,
    viewPoint1: [number, number],
    viewPoint2: [number, number],
    resLenght: number
  ) {
    this.prevSearchResults = this.searchResults;
    const searchResults = await this.api.search.getSearchResults(
      idType,
      id,
      viewPoint1,
      viewPoint2,
      resLenght
    );

    runInAction(() => {
      this.searchResults = searchResults.data?.result?.items as ResultItem[];
    });

    return searchResults.data?.result?.items || [];
  }

  @action async getSearchResultsByQuery(
    inputValue: string,
    viewPoint1: [number, number],
    viewPoint2: [number, number]
  ) {
    this.prevSearchResults = this.searchResults;

    const res = await this.api.search.getSearchResultsByQuery(
      inputValue,
      viewPoint1,
      viewPoint2
    );
    runInAction(() => {
      this.searchResults = (res.data?.result?.items as ResultItem[]) || [];
    });

    return res.data?.result?.items || [];
  }

  @action async getSuggestions(
    inputValue: string,
    viewPoint1: [number, number],
    viewPoint2: [number, number]
  ) {
    this.prevSuggestionsResults = this.suggestionsResults;
    const suggestionsResults = await this.api.search.getSuggestions(
      inputValue,
      viewPoint1,
      viewPoint2
    );

    const sortedItems: any[] = [];

    if (suggestionsResults.data.result) {
      // eslint-disable-next-line array-callback-return
      suggestionsResults.data.result.items?.map((item: any) => {
        if (item.type !== "user_query") {
          sortedItems.push(item);
        }
      });
    }

    runInAction(() => {
      this.suggestionsResults = sortedItems;
    });

    return this.suggestionsResults;
  }

  @action
  async getObjectById(id: string) {
    this.prevObject = this.currentObject;
    const result = await this.api.search.getObjectById(id);

    runInAction(() => {
      this.currentObject = result.data as any;
    });

    return this.currentObject;
  }

  @action
  async getBranchesByBuildingId(id: string) {
    const result = await this.api.search.getBranchesByBuildingId(id);

    runInAction(() => {
      this.branchesInBuilding = result.data as any;
    });
    return this.branchesInBuilding;
  }

  @action
  async getBranchesByOrganizationId(id: string) {
    const result = await this.api.search.getBranchesByOrganizationId(id);

    runInAction(() => {
      this.branchesInOrganization = result.data as any;
    });
    return this.branchesInBuilding;
  }

  @action setCurrentObject(item: ResultItem | null): ResultItem {
    if (item && item.address) {
      this.getBranchesByBuildingId(item.address.building_id);
      if (item.org) {
        this.getBranchesByOrganizationId(item.org.id);
      }
    }
    return (this.currentObject = item as any);
  }

  // TODO: refactored this
  getObjectByGeo(sortPoint: [number, number]): any {
    return this.api.search.getObjectByGeo(sortPoint);
  }
}
