import styled from "styled-components";
import BackArrow from "assets/images/back-arrow.svg";
import SubList from "assets/images/sub-list-item.svg";
import { useHistory } from "react-router-dom";

const SubscriptionPage = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  padding: 0 16px 0 16px;
`;

const BackArrowWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 16px;
  display: flex;
  align-items: center;
  padding-left: 27px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
`;

const BackArrowIcon = styled.div`
  background-image: url(${BackArrow});
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 28px;
`;

const ScrollWrapper = styled.div`
  width: 100%;
  height: 670px;
  position: relative;
  overflow: scroll;
`;

const Header = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const SubscriptionItemWrapper = styled.div`
  width: 100%;
  padding: 24px 16px 24px 16px;
  border-radius: 5px;
  border: 1px solid #23b064;
  margin-bottom: 16px;
`;

const SubscriptionItemHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SubscriptionType = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

const SubscriptionPrice = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.15px;
`;

const PriceListItem = styled.div`
  width: 100%;
  margin-bottom: 4px;
  display: flex;
`;

const PriceListText = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

const SubListIcon = styled.div`
  background-image: url(${SubList});
  background-repeat: no-repeat;
  width: 20px;
  height: 15px;
  margin-top: 4px;
  margin-right: 4px;
`;

const ManageButton = styled.a`
  background: rgba(0, 161, 84, 0.08);
  border: 1px solid #23b064;
  border-radius: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #23b064;
  width: 194px;
  padding: 6px;
  margin-top: 35px;
`;

const Info = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 16px;
`;

export const ManageSubscription = () => {
  const history = useHistory();

  return (
    <SubscriptionPage>
      <BackArrowWrapper>
        <BackArrowIcon
          onClick={() => {
            history.push("/settings");
          }}
        />
        <Header>Manage Subscription</Header>
      </BackArrowWrapper>
      <ScrollWrapper>
        <SubscriptionItemWrapper>
          <SubscriptionItemHeaderWrapper style={{ marginBottom: "24px" }}>
            <SubscriptionType>Premium</SubscriptionType>
            <SubscriptionPrice>€10/month</SubscriptionPrice>
          </SubscriptionItemHeaderWrapper>
          <PriceListItem>
            <SubListIcon />
            <PriceListText>Unlimited navigator launches</PriceListText>
          </PriceListItem>
          <PriceListItem>
            <SubListIcon />
            <PriceListText>
              Unlimited number of times share a place with GoogleMaps links
            </PriceListText>
          </PriceListItem>
          <PriceListItem>
            <SubListIcon />
            <PriceListText>
              Unlimited location tracking along route
            </PriceListText>
          </PriceListItem>

          <ManageButton href="https://play.google.com/store/account/subscriptions?package=com.cynavi&sku=navicy_premium">
            Manage Subscription
          </ManageButton>

          <Info>Auto-renewal on 21 Jun 2021</Info>
          <Info>
            If you want to renew your subscription for €10 a month, you can do
            so in your Play Market account settings. Once your subscription is
            renewed, payment will be made on June 21 and your subscription will
            automatically renew after payment within 24 hours.
          </Info>
        </SubscriptionItemWrapper>
        <SubscriptionItemWrapper style={{ marginBottom: "100px" }}>
          <SubscriptionItemHeaderWrapper style={{ marginBottom: "24px" }}>
            <SubscriptionType>Basic</SubscriptionType>
            <SubscriptionPrice>€5/month</SubscriptionPrice>
          </SubscriptionItemHeaderWrapper>
          <PriceListItem>
            <SubListIcon />
            <PriceListText>100 navigator launches</PriceListText>
          </PriceListItem>
          <PriceListItem>
            <SubListIcon />
            <PriceListText>
              100 times share a place with GoogleMaps links
            </PriceListText>
          </PriceListItem>
          <PriceListItem>
            <SubListIcon />
            <PriceListText>
              100 times location tracking along route
            </PriceListText>
          </PriceListItem>

          <ManageButton href="https://play.google.com/store/account/subscriptions?package=com.cynavi&sku=navicy_basic">
            Manage Subscription
          </ManageButton>
        </SubscriptionItemWrapper>
      </ScrollWrapper>
    </SubscriptionPage>
  );
};
