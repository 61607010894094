import styled from "styled-components";
import ArrowRight from "assets/images/arrow-right.svg";
import {
  SettingsButton,
  SubscriptionButtonText,
  SubscriptionButtonTextContainer,
} from "./SettingsButton";
import { useHistory } from "react-router";
import BackArrow from "assets/images/back-arrow.svg";
import { useEffect, useState } from "react";
import { useLocalStorageStore } from "hooks/store";
import { observer } from "mobx-react-lite";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BackArrowWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 16px;
  display: flex;
  align-items: center;
  padding-left: 27px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
`;

const BackArrowIcon = styled.div`
  background-image: url(${BackArrow});
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 28px;
`;

const ArrowRightIcon = styled.p`
  background-image: url(${ArrowRight});
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-right: 20px;
`;

const LawContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(33, 33, 33, 0.08);
  border-radius: 16px;
  width: 93%;
  margin: 8px 0;
`;

const LawButtonContainer = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Line = styled.p`
  width: 93%;
  border: 1px solid rgba(33, 33, 33, 0.08);
  align-self: center;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
`;

export const Settings = observer(() => {
  const history = useHistory();
  const localStorageStore = useLocalStorageStore();

  const [subStatus, setSubStatus] = useState<string | null>(null);
  useEffect(() => {
    if (
      localStorageStore.subType === "premium" ||
      localStorageStore.subType === "basic"
    ) {
      setSubStatus("active");
    } else {
      setSubStatus("inactive");
    }
  }, [localStorageStore.subType]);

  const manageSubscriptionHandler = () => {
    history.push("/settings/subscription");

    // subStatus === "active"
    //   ? history.push("/settings/manage-subscription")
    //   : history.push("/settings/subscription");
  };

  const contactsHandler = () => {
    history.push("/settings/contacts");
  };
  return (
    <Wrapper>
      <BackArrowWrapper>
        <BackArrowIcon
          onClick={() => {
            history.push("/");
          }}
        />
        <Header>Settings</Header>
      </BackArrowWrapper>
      <SettingsButton
        isSubscriptionButton={true}
        handleClick={manageSubscriptionHandler}
        subStatus={subStatus}
      >
        Manage Subscription
      </SettingsButton>
      <SettingsButton handleClick={contactsHandler}>Contact Us</SettingsButton>
      <LawContainer>
        <LawButtonContainer href="https://navicy.com.cy/terms-of-use/">
          <SubscriptionButtonTextContainer>
            <SubscriptionButtonText>Terms of Use</SubscriptionButtonText>
          </SubscriptionButtonTextContainer>
          <ArrowRightIcon />
        </LawButtonContainer>
        <Line />
        <LawButtonContainer href="https://navicy.com.cy/privacy-policy/">
          <SubscriptionButtonTextContainer>
            <SubscriptionButtonText>Privacy Policy</SubscriptionButtonText>
          </SubscriptionButtonTextContainer>
          <ArrowRightIcon />
        </LawButtonContainer>
      </LawContainer>
    </Wrapper>
  );
});
