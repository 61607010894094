import styled from "styled-components";
import React, { MutableRefObject, useEffect, useState } from "react";
import { DirectionsStore } from "stores/DirectionsStore";
import { SearchStore } from "stores/SearchStore";
import { observer } from "mobx-react";
import { MapglStore } from "stores/MapglStore";
import { SendData } from "../utils/SendData";
import { PostMessageTypeEnum } from "../enums/PostMessageType.enum";
import TwoGisDisabled from "assets/images/two-gis-disabled.svg";
import Google from "assets/images/google.svg";
import Waze from "assets/images/waze.svg";
import { NavigatorsEnum } from "enums/Navigators.enum";
import { useHistory } from "react-router-dom";
import { LocalStorageStore } from "../stores/LocalStorageStore";
import { LocalStorageFieldsEnum } from "../enums/LocalStorageFieldsEnum.enum";

const DirectionCardWrapper = styled.div`
  position: absolute;
  bottom: 0;
  background: #ffffff;
  z-index: 52;
  width: 100%;
  height: fit-content;
  text-align: center;
`;

const DirectionButtonsWrapper = styled.div`
  width: 100%;
  height: 60px;
  display: grid;
  grid-template-columns: 48% 48%;
  padding: 10px 16px 0 16px;
  margin-bottom: 24px;
  justify-content: space-between;
`;

const DirectionButton = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 9px;
`;

const RouteTime = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  align-self: self-start;
  margin-left: 5%;
`;

const Distance = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
`;

const LetsGoButton = styled.button`
  width: 315px;
  height: 48px;
  background: #23b064;
  border-radius: 12px;
  margin-bottom: 10px;
  margin-top: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`;

const DirectionsLength = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 20px;
`;

const NavigatorsContainer = styled.div`
  position: absolute;
  bottom: 0;
  background: #ffffff;
  z-index: 54;
  width: 100%;
  height: 20%;
  text-align: center;
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const TwoGisIcon = styled.div`
  background-image: url(${TwoGisDisabled});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ebebeb;
  border-radius: 17px;
  width: 48px;
  height: 48px;
`;

const GoogleIcon = styled.div`
  background-image: url(${Google});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ebebeb;
  border-radius: 17px;
  width: 48px;
  height: 48px;
`;

const WazeIcon = styled.div`
  background-image: url(${Waze});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #ebebeb;
  border-radius: 17px;
  width: 48px;
  height: 48px;
`;

const NaviContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ScreenContainer = styled.div`
  z-index: 53;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;

const NaviText = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  width: 80px;
`;

enum DirectionType {
  faster = "jam",
  shorter = "shortest",
}

interface DirectionCardProps {
  directionsStore: DirectionsStore;
  mapglStore: MapglStore;
  searchStore: SearchStore;
  localStorageStore: LocalStorageStore;
  currentGeolocation: [number, number] | [null, null];
  checkDirection: MutableRefObject<(() => void) | null>;
}

export const DirectionCard = observer(
  ({
    directionsStore,
    searchStore,
    mapglStore,
    currentGeolocation,
    localStorageStore,
    checkDirection,
  }: DirectionCardProps) => {
    const history = useHistory();
    useEffect(() => {
      history.push("/direction");
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [selectedDirection, setSelectedDirection] = useState(
      DirectionType.faster
    );

    const [faster, setFaster] = useState<any>(null);
    const [shortest, setShortest] = useState<any>(null);
    const [isNavigatorsShown, setIsNavigatorsShown] = useState(false);

    const buildDirection = (
      endPoint: number[],
      type: string,
      color: string
    ): Promise<any> => {
      return directionsStore.buildDirection(
        currentGeolocation as [number, number],
        endPoint,
        type,
        color
      );
    };

    useEffect(() => {
      checkDirection.current = createDirection;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createDirection = () => {
      searchStore.currentObject &&
        (mapglStore.currentMarkerCoords || searchStore.currentObject.point) &&
        buildDirection(
          mapglStore.currentMarkerCoords || [
            searchStore.currentObject.point.lon,
            searchStore.currentObject.point.lat,
          ],
          selectedDirection,
          selectedDirection === DirectionType.faster ? "#23B064" : "#0090FF"
        ).then((res) => {
          searchStore.currentObject &&
            (mapglStore.currentMarkerCoords ||
              searchStore.currentObject.point) &&
            directionsStore
              .getDirection(
                currentGeolocation as [number, number],
                mapglStore.currentMarkerCoords || [
                  searchStore.currentObject.point.lon,
                  searchStore.currentObject.point.lat,
                ],
                DirectionType.faster
              )
              .then((res) => {
                setFaster(res.data[0]);
              });

          searchStore.currentObject &&
            (mapglStore.currentMarkerCoords ||
              searchStore.currentObject.point) &&
            directionsStore
              .getDirection(
                currentGeolocation as [number, number],
                mapglStore.currentMarkerCoords || [
                  searchStore.currentObject.point.lon,
                  searchStore.currentObject.point.lat,
                ],
                DirectionType.shorter
              )
              .then((res) => {
                setShortest(res.data[0]);
              });
        });
    };

    useEffect(() => {
      createDirection();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      selectedDirection,
      directionsStore,
      searchStore.currentObject,
      mapglStore.currentMarkerCoords,
    ]);

    const onNavigatorPress = (navigator: NavigatorsEnum) => {
      if (mapglStore.currentMarkerCoords) {
        SendData({
          type: PostMessageTypeEnum.ROUTE,
          data: {
            navigator: navigator,
            currentPoint: `${currentGeolocation[1]},${currentGeolocation[0]}`,
            routPoint: `${mapglStore.currentMarkerCoords[1]},${mapglStore.currentMarkerCoords[0]}`,
          },
        });
        localStorageStore.reduceCount(LocalStorageFieldsEnum.LETS_GO_COUNT);
      }
    };

    return (
      <>
        <DirectionCardWrapper>
          <DirectionButtonsWrapper>
            <DirectionButton
              onClick={() => {
                selectedDirection !== DirectionType.faster &&
                  mapglStore.destroyDirection();
                setSelectedDirection(DirectionType.faster);
              }}
              style={
                selectedDirection === DirectionType.faster
                  ? {
                      border: "1px solid #23B064",
                      background: "rgba(0, 161, 84, 0.08)",
                      borderRadius: "4px",
                    }
                  : {
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: "4px",
                    }
              }
            >
              <RouteTime>{(faster?.duration / 60).toFixed(1)} min</RouteTime>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Distance>{(faster?.distance / 1000).toFixed(1)} km</Distance>
                <div>faster</div>
              </div>
            </DirectionButton>
            <DirectionButton
              onClick={() => {
                selectedDirection !== DirectionType.shorter &&
                  mapglStore.destroyDirection();
                setSelectedDirection(DirectionType.shorter);
              }}
              style={
                selectedDirection === DirectionType.shorter
                  ? {
                      border: "1px solid #23B064",
                      background: "rgba(0, 161, 84, 0.08)",
                      borderRadius: "4px",
                    }
                  : {
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: "4px",
                    }
              }
            >
              <RouteTime>{(shortest?.duration / 60).toFixed(1)} min</RouteTime>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}
              >
                <Distance>{(shortest?.distance / 1000).toFixed(1)} km</Distance>
                <div>shorter</div>
              </div>
            </DirectionButton>
          </DirectionButtonsWrapper>
          <LetsGoButton
            onClick={() => {
              if (
                !localStorageStore.letsGoCount ||
                !parseInt(localStorageStore.letsGoCount as any)
              ) {
                history.push("/settings/subscription");
              } else {
                setIsNavigatorsShown(true);
              }
            }}
            style={{
              background:
                !localStorageStore.letsGoCount ||
                !parseInt(localStorageStore.letsGoCount as any)
                  ? "#A9A9A9"
                  : "#23b064",
            }}
          >
            Let`s go
          </LetsGoButton>
          <DirectionsLength>
            You have{" "}
            {localStorageStore.subType === "premium"
              ? "unlimit"
              : localStorageStore.letsGoCount}{" "}
            routes left
          </DirectionsLength>
        </DirectionCardWrapper>
        {isNavigatorsShown && (
          <ScreenContainer onClick={() => setIsNavigatorsShown(false)}>
            <NavigatorsContainer>
              <NaviContainer
                onClick={() => onNavigatorPress(NavigatorsEnum.TWO_GIS)}
              >
                <TwoGisIcon />
                <NaviText style={{ color: "#9D9D9D" }}>
                  {NavigatorsEnum.TWO_GIS}
                </NaviText>
              </NaviContainer>
              <NaviContainer
                onClick={() => onNavigatorPress(NavigatorsEnum.WAZE)}
              >
                <WazeIcon />
                <NaviText>{NavigatorsEnum.WAZE}</NaviText>
              </NaviContainer>
              <NaviContainer
                onClick={() => onNavigatorPress(NavigatorsEnum.GOOGLE)}
              >
                <GoogleIcon />
                <NaviText style={{ fontSize: 14 }}>
                  {NavigatorsEnum.GOOGLE}
                </NaviText>
              </NaviContainer>
            </NavigatorsContainer>
          </ScreenContainer>
        )}
      </>
    );
  }
);
