export enum ResponseObjectTypes {
  branch = "branch",
  building = "building",
  street = "street",
  parking = "parking",
  station = "station",
  stationMetro = "station.metro",
  stationEntrance = "station_entrance",
  stationPlatform = "station_platform",
  attraction = "attraction",
  crossroad = "crossroad",
  gate = "gate",
  road = "road",
  route = "route",
  admDiv = "adm_div",
  admDivCity = "adm_div.city",
  admDivCountry = "adm_div.country",
  admDivDistrictArea = "adm_div.district_area",
  admDivDistrict = "adm_div.district",
  admDivDivision = "adm_div.division",
  admDivLivingArea = "adm_div.living_area",
  admDivPlace = "adm_div.place",
  admDivRegion = "adm_div.region",
  admDivSettlement = "adm_div.settlement",
  coordinates = "coordinates",
  rubric = "rubric",
  metarubric = "metarubric",
  organization = "org",
}
