import { FC } from "react";
import styled from "styled-components";
import ArrowLeft from "assets/images/arrow-left.svg";
import { useMapglStore, useSearchStore } from "../hooks/store";
import { SearchStore } from "stores/SearchStore";
import { ResultItem } from "api/interfaces/search.interface";
import { MapglStore } from "stores/MapglStore";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: absolute;
  z-index: 900;
  background-color: #ffffff;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 375px;
  height: 64px;
  margin-top: 10px;
  border-bottom: solid 1px rgba(33, 33, 33, 0.08);
  border-bottom-color: rgba(33, 33, 33, 0.08);
`;

const HeaderTitle = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

const ArrowLeftIcon = styled.p`
  background-image: url(${ArrowLeft});
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin: 0 32px;
`;

const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 64px;
  border-bottom: solid 1px rgba(33, 33, 33, 0.08);
  border-bottom-color: rgba(33, 33, 33, 0.08);
`;

const CompanyName = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 16px;
`;

const CompanyAdditionalInfo = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
  margin-left: 16px;
`;

const CompaniesContainer = styled.div`
  width: 100%;
  height: 800px;
  overflow: scroll;
`;

interface CompanyButtonProps {
  setIsOpen: (arg0: boolean) => void;
  searchStore: SearchStore;
  item: ResultItem;
  mapglStore: MapglStore;
}

const CompanyButton: FC<CompanyButtonProps> = ({
  setIsOpen,
  searchStore,
  item,
}) => {
  const openCompanyCard = () => {
    searchStore.getObjectById(item.id).then((res: any) => {
      res && searchStore.setCurrentObject(res.result?.items[0]);
      setIsOpen(false);
    });
  };

  
  return (
    <CompanyContainer onClick={() => openCompanyCard()}>
      <CompanyName>{`${item.name}`}</CompanyName>
      <CompanyAdditionalInfo>{`${
        item.address_comment || item.purpose_name
      }`}</CompanyAdditionalInfo>
    </CompanyContainer>
  );
};

interface CompaniesListProps {
  setIsOpen: (arg0: boolean) => void;
}

export const CompaniesList: FC<CompaniesListProps> = ({ setIsOpen }) => {
  const searchStore = useSearchStore();
  const mapglStore = useMapglStore();

  return (
    <Wrapper>
      <Header>
        <ArrowLeftIcon onClick={() => setIsOpen(false)} />
        <HeaderTitle>Companies</HeaderTitle>
      </Header>
      <CompaniesContainer>
        {searchStore.branchesInBuilding.result.items.map((item: any) => (
          <CompanyButton
            key={item.id}
            item={item}
            setIsOpen={setIsOpen}
            searchStore={searchStore}
            mapglStore={mapglStore}
          />
        ))}
      </CompaniesContainer>
    </Wrapper>
  );
};
