import styled from "styled-components";
import BackArrow from "assets/images/back-arrow.svg";
import Watsapp from "assets/images/watsapp.svg";
import Viber from "assets/images/viber.svg";
import { useHistory } from "react-router";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const BackArrowWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  padding: 16px;
  display: flex;
  align-items: center;
  padding-left: 27px;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
`;

const BackArrowIcon = styled.div`
  background-image: url(${BackArrow});
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-right: 28px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 8px;
`;

const Number = styled.div`
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  margin-top: 16px;
  text-align: start;
  width: 100%;
  padding: 16px;
`;

const Description = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  text-align: start;
  width: 100%;
  padding: 16px;
  margin-bottom: 40px;
`;

const SocialWrapper = styled.div`
  width: 100%;
  display: flex;
`;

const WatsappIcon = styled.div`
  background-image: url(${Watsapp});
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
  margin-right: 8px;
  margin-left: 16px;
`;

const ViberIcon = styled.div`
  background-image: url(${Viber});
  background-repeat: no-repeat;
  width: 46px;
  height: 46px;
`;

export const Contacts = () => {
  const history = useHistory();
  return (
    <Wrapper>
      <BackArrowWrapper>
        <BackArrowIcon
          onClick={() => {
            history.push("/settings");
          }}
        />
        <Header>Contact us</Header>
      </BackArrowWrapper>
      <Number>+ 357 97 792370</Number>
      <Description>
        Call us and we'll help or text us via messengers
      </Description>
      <SocialWrapper>
        <WatsappIcon />
        <ViberIcon />
      </SocialWrapper>
    </Wrapper>
  );
};
