import { action, observable } from "mobx";
import { RootStore } from "./RootStore";
import { load } from "@2gis/mapgl";
import mapglTypes from "@2gis/mapgl/types/index";
export class MapglStore {
  rootStore: RootStore;
  @observable mapglAPI: typeof mapglTypes | null = null;
  @observable mapgl: mapglTypes.Map | null = null;
  @observable markers: mapglTypes.Marker[] | [] = [];
  @observable polygon: mapglTypes.Polygon | null = null;
  @observable polyline: mapglTypes.Polyline | null = null;
  @observable currentMarkerCoords: number[] | null = null;
  @observable currentMarker: mapglTypes.Marker | null = null;
  @observable locationMarker: mapglTypes.Marker | null = null;
  @observable myLocation: number[] | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
  }

  @action async loadMapglAPI(): Promise<any> {
    this.mapglAPI = await load();
  }

  @action loadMapgl(
    divId: string,
    options: mapglTypes.MapOptions
  ): mapglTypes.Map | null {
    this.mapgl = this.mapglAPI
      ? new this.mapglAPI.Map(divId, {
          ...options,
          zoomControl: false,
          style: "1d8f661e-60aa-4eee-bf40-d4d911436fe7",
        })
      : null;
    this.mapgl && this.rootStore.directionsStore.loadDirections(this.mapgl);
    return this.mapgl;
  }

  @action setLocationMarker(coordinates: number[]) {
    if (this.mapgl && this.mapglAPI) {
      this.locationMarker = new this.mapglAPI.Marker(this.mapgl, {
        coordinates,
      });
    }
  }

  @action setMyLocation(coordinates: number[]): number[] {
    return (this.myLocation = coordinates);
  }

  @action buildMarker(
    coords: number[],
    isActive: boolean = false,
    isCurrentMarker: boolean = true
  ): mapglTypes.Marker | null {
    if (this.mapgl && this.mapglAPI) {
      const currentMarker = new this.mapglAPI.Marker(this.mapgl, {
        coordinates: coords,
        icon: isActive ? "./red-marker.svg" : "./marker.svg",
      });
      this.markers.push(currentMarker as never);
      if (isCurrentMarker) {
        this.setCurrentMarkerCoords(coords);
      }
      return currentMarker;
    }
    return null;
  }

  @action setCurrentMarker(marker: mapglTypes.Marker): mapglTypes.Marker {
    return (this.currentMarker = marker);
  }

  @action buildPolygon(coordinates: number[][][]) {
    this.polygon && this.polygon.destroy();

    if (this.mapglAPI && this.mapgl && coordinates) {
      this.polygon =  new this.mapglAPI.Polygon(this.mapgl, {
        coordinates: coordinates,
        interactive: false,
      });
    }
  }

  @action buildDirection(coordinates: number[][], color: string) {
    this.destroyDirection();
    if (this.mapglAPI && this.mapgl) {
      this.polyline = new this.mapglAPI.Polyline(this.mapgl, {
        coordinates: coordinates,
        color,
        width: 6,
      });
    }
  }

  @action destroyDirection() {
    this.polyline?.destroy();
    this.polyline = null;
  }

  @action destroyPolygon() {
    this.polygon?.destroy();
    this.polygon = null;
  }

  @action destroyMarkers() {
    this.markers.forEach((marker) => {
      marker.destroy();
    });
    this.markers = [];
    this.currentMarker?.destroy();
  }

  setCenter(coordinates: [number, number]) {
    this.mapgl?.setCenter(coordinates, {});
  }

  getZoom(): number | undefined {
    return this.mapgl?.getZoom();
  }

  setZoom(zoom: number) {
    this.mapgl?.setZoom(zoom);
  }

  @action setCurrentMarkerCoords(coords: number[]): number[] {
    return (this.currentMarkerCoords = coords);
  }
}
