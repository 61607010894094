export enum LocalStorageFieldsEnum {
  LETS_GO_COUNT = "letsGoCount",
  SHARE_COUNT = "shareCount",
  ROUT_HANDLER_COUNT = "routHandlerCount",
  CURRENT_OBJECT_ID = "currentObjectId",
  CURRENT_OBJECT = "currentObject",
  CURRENT_MARKER_COORDS = "currentMarkerCoordinates",
  IS_DIRECTION_CARD = "isDirectionCard",
  SUB_TYPE = "subType"
}
