import {
  BottomInfo,
  BottomText,
  GreenMarkerIcon,
} from "../styles/cardStyles";
import { FC } from "react";
import { SearchStore } from "../stores/SearchStore";
import { MapglStore } from "stores/MapglStore";
import { PostMessageData } from "../api/interfaces/postMassage.interface";

interface AdministrativeDivisionCardProps {
  isOpen: boolean;
  searchStore: SearchStore;
  shareData: (arg0: PostMessageData) => void;
  mapglStore: MapglStore;
  coordinates: [number, number];
  getAddress: () => string | undefined;
}

export const AdministrativeDivisionCard: FC<AdministrativeDivisionCardProps> =
  ({ isOpen, searchStore, mapglStore, coordinates, getAddress }) => {
    return (
      <>
        {searchStore.currentObject?.address ? (
          <BottomInfo>
            <GreenMarkerIcon />
            <BottomText>{getAddress()}</BottomText>
          </BottomInfo>
        ) : (
          <BottomInfo>
            <GreenMarkerIcon
              onClick={() => mapglStore.setCenter(coordinates)}
            />
            <BottomText onClick={() => mapglStore.setCenter(coordinates)}>
              {searchStore.currentObject?.full_name}
            </BottomText>
          </BottomInfo>
        )}
      </>
    );
  };
