import { PostMessageData } from "../api/interfaces/postMassage.interface";

declare global {
  interface Window {
    ReactNativeWebView: any;
  }
}

export const SendData = (data: PostMessageData): void => {
  window.ReactNativeWebView &&
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
};
