import React from "react";
import { RootStore } from "stores/RootStore";

export const StoreContext = React.createContext<RootStore>({} as RootStore);

export type StoreComponent = React.FC<{
  store: RootStore;
  children: React.ReactNode;
}>;

export const StoreProvider: StoreComponent = ({
  children,
  store,
}): React.ReactElement => {
  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};
