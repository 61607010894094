import BaseAPI from "./base";
import { API_VERSIONS } from "./enums/api-versions.enum";
import { SearchResponse } from "./interfaces/search.interface";

export const ID_TYPES: any = {
  district: "district_id",
  building: "building_id",
  city: "city_id",
  region: "region_id",
  rubric: "rubric_id",
  metarubric: "rubric_id",
  org: "org_id",
  id: "id",
};

export default class SearchAPI extends BaseAPI {
  async getSearchResults(
    idType: string,
    id: number,
    viewPoint1: [number, number],
    viewPoint2: [number, number],
    resLenght: number
  ): Promise<any> {
    const res = (await this.axios.get(
      `${API_VERSIONS.V3}/items?page=1&page_size=${resLenght}&${idType}=${id}&viewpoint1=${viewPoint2[0]},${viewPoint1[1]}&viewpoint2=${viewPoint1[0]},${viewPoint2[1]}&fields=items.locale,items.flags,search_attributes,items.adm_div,items.city_alias,items.region_id,items.segment_id,items.reviews,items.point,request_type,context_rubrics,query_context,items.links,items.name_ex,items.org,items.group,items.external_content,items.comment,items.ads.options,items.email_for_sending.allowed,items.stat,items.description,items.geometry.centroid,items.geometry.selection,items.geometry.style,items.timezone_offset,items.context,items.address,items.is_paid,items.access,items.access_comment,items.for_trucks,items.is_incentive,items.paving_type,items.capacity,items.schedule,items.floors,dym,ad,items.rubrics,items.routes,items.reply_rate,items.purpose,items.route_logo,items.has_goods,items.has_apartments_info,items.has_pinned_goods,items.has_realty,items.has_payments,items.is_promoted,items.delivery,items.order_with_cart,search_type,items.has_discount,items.metarubrics,broadcast,items.detailed_subtype,filters,widgets&key=${process.env.REACT_APP_SEARCH_KEY}&locale=en_CY`
    )) as SearchResponse;
    return res;
  }

  async getSearchResultsByQuery(
    inputValue: string,
    viewPoint1: [number, number],
    viewPoint2: [number, number]
  ): Promise<any> {
    const res = (await this.axios.get(
      `${API_VERSIONS.V3}/items?q=${inputValue}&viewpoint1=${viewPoint2[0]},${viewPoint1[1]}&viewpoint2=${viewPoint1[0]},${viewPoint2[1]}&fields=items.locale,items.flags,search_attributes,items.adm_div,items.city_alias,items.region_id,items.segment_id,items.reviews,items.point,request_type,context_rubrics,query_context,items.links,items.name_ex,items.org,items.group,items.external_content,items.comment,items.ads.options,items.email_for_sending.allowed,items.stat,items.description,items.geometry.centroid,items.geometry.selection,items.geometry.style,items.timezone_offset,items.context,items.address,items.is_paid,items.access,items.access_comment,items.for_trucks,items.is_incentive,items.paving_type,items.capacity,items.schedule,items.floors,dym,ad,items.rubrics,items.routes,items.reply_rate,items.purpose,items.route_logo,items.has_goods,items.has_apartments_info,items.has_pinned_goods,items.has_realty,items.has_payments,items.is_promoted,items.delivery,items.order_with_cart,search_type,items.has_discount,items.metarubrics,broadcast,items.detailed_subtype,filters,widgets&type=adm_div.city,adm_div.district,adm_div.district_area,adm_div.division,adm_div.living_area,adm_div.place,adm_div.region,adm_div.settlement,attraction,branch,building,crossroad,foreign_city,gate,parking,road,route,station,street,coordinates&page_size=50&page=1&key=${process.env.REACT_APP_SEARCH_KEY}`
    )) as SearchResponse;
    return res;
  }

  async getSuggestions(inputValue: string,
    viewPoint1: [number, number],
    viewPoint2: [number, number]): Promise<any> {

    const res = await this.axios
      .get(
        `${API_VERSIONS.V3}/suggests?key=${process.env.REACT_APP_SEARCH_KEY}&q=${inputValue}&viewpoint1=${viewPoint2[0]},${viewPoint1[1]}&viewpoint2=${viewPoint1[0]},${viewPoint2[1]}&page_size=20&fields=items.name_ex,items.address,items.full_address_name,items.rubrics,items.org,items.adm_div,items.routes,items.type,items.subtype,items.address,items.search_attributes.personal_priority,items.search_attributes.dgis_source_type,items.segment_id,items.region_id,items.locale,items.group,items.context,search_attributes,items.flags,items.has_exchange&type=adm_div.region,adm_div.district_area,adm_div.city,adm_div.settlement,adm_div.district,adm_div.living_area,adm_div.division,adm_div.place,street,branch,building,road,attraction,crossroad,route,route_type,station,station.metro,user_queries,attribute,rubric,meta_rubric,org,market.category,market.attribute,market.suggestor_category`
      )
    return res;
  }

  async getObjectById(id: string) {
    const res = await this.axios.get(
      `${API_VERSIONS.V3}/items/byid?id=${id}&fields=items.point,items.adm_div,items.address,items.full_address_name,items.links,items.floors,items.contact_groups,items.schedule,items.rubrics,items.org&key=${process.env.REACT_APP_SEARCH_KEY}`
    );
    return res;
  }

  async getObjectByGeo(sortPoint: [number, number]) {
    const res = await this.axios.get(
      `${API_VERSIONS.V2}/geo/search?key=${process.env.REACT_APP_SEARCH_KEY}&point=${sortPoint[0]},${sortPoint[1]}&type=adm_div.city,adm_div.region,adm_div.settlement,road,adm_div.district_area,adm_div.division,adm_div.district,street,adm_div.living_area,crossroad,building,adm_div.place,attraction,parking,poi&fields=items.locale,items.flags,search_attributes,items.adm_div,items.region_id,items.segment_id,items.reviews,items.point,request_type,context_rubrics,query_context,items.links,items.name_ex,items.org,items.group,items.external_content,items.comment,items.ads.options,items.email_for_sending.allowed,items.stat,items.description,items.geometry.centroid,items.geometry.selection,items.geometry.style,items.timezone_offset,items.context,items.address,items.is_paid,items.access,items.access_comment,items.capacity,items.schedule,items.floors,dym,ad,items.rubrics,items.routes,items.reply_rate,items.purpose,items.route_logo,items.has_goods,items.has_apartments_info,items.has_pinned_goods,items.has_realty,items.has_payments,items.is_promoted,items.delivery,items.order_with_cart,search_type,items.has_discount,items.metarubrics,broadcast,items.detailed_subtype`
    );
    return res;
  }

  async getDirections(
    startPoint: { x: number; y: number },
    endPoint: { x: number; y: number }
  ) {
    const res = await this.axios.post(
      `carrouting/6.0.0/global?key=${process.env.REACT_APP_SEARCH_KEY}`,
      {
        points: [startPoint, endPoint],
      }
    );

    return res;
  }

  async getBranchesByBuildingId(id: string) {
    const res = await this.axios.get(
      `${API_VERSIONS.V3}/items?building_id=${id}&key=${process.env.REACT_APP_SEARCH_KEY}`
    );
    return res;
  }

  async getBranchesByOrganizationId(id: string) {
    const res = await this.axios.get(
      `${API_VERSIONS.V2}/catalog/branch/list?org_id=${id}&fields=items.point,items.address,items.links,items.floors,items.contact_groups,items.schedule,items.rubrics,items.org,items.full_address_name&key=${process.env.REACT_APP_SEARCH_KEY}`
    );
    return res;
  }
}
