import { FC, useState } from "react";
import { SearchStore } from "stores/SearchStore";
import Swipe from "react-easy-swipe";
import { Places } from "../assets/data/places";
import { CompaniesList } from "./CompaniesList";
import { ResponseObjectTypes } from "../enums/ResponseObjectTypes.enum";
import { BuildingCard } from "./BuildingCard";
import { AdministrativeDivisionCard } from "./AdministarativeDivisionCard";
import {
  ArrowDownIcon,
  Divider,
  IconButton,
  IconButtonContainer,
  PlaceInfo,
  PlaceInfoWrapper,
  PlaceTitle,
  ShareIcon,
  SwipeIndicator,
  UpperInfo,
  Wrapper,
} from "../styles/cardStyles";
import { OrganizationCart } from "./OrganizationCart";
import { observer } from "mobx-react";
import { MapglStore } from "stores/MapglStore";
import { PostMessageTypeEnum } from "../enums/PostMessageType.enum";
import { SendData } from "../utils/SendData";
import { PostMessageData } from "../api/interfaces/postMassage.interface";
import mapglTypes from "@2gis/mapgl/types/index";
import { LocalStorageStore } from "../stores/LocalStorageStore";
import { LocalStorageFieldsEnum } from "../enums/LocalStorageFieldsEnum.enum";

interface ObjectCardProps {
  searchStore: SearchStore;
  mapglStore: MapglStore;
  localStorageStore: LocalStorageStore;
  setIsDirectionCard: (value: boolean) => void;
  handleMapClick: (data: mapglTypes.MapPointerEvent) => void;
  setCurrentLocation: (data: string) => void;
  currentLocation: string | undefined;
  searchInputRef: React.RefObject<HTMLInputElement>;
}

export const ObjectCard: FC<ObjectCardProps> = observer(
  ({
    searchStore,
    mapglStore,
    setIsDirectionCard,
    handleMapClick,
    localStorageStore,
    searchInputRef,
  }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isCompaniesListOpen, setCompaniesListIsOpen] = useState(false);

    const canBeSwiped =
      searchStore.currentObject?.type === ResponseObjectTypes.branch ||
      searchStore.currentObject?.type === ResponseObjectTypes.building;

    const setType = (type?: string) => {
      for (let item of Places) {
        if (type === item.type) {
          return item.title;
        }
      }
      return "";
    };

    const shareData = (data: PostMessageData) => {
      if (PostMessageTypeEnum.SHARE_INFO === data.type) {
        if (mapglStore.currentMarkerCoords) {
          const twoGisLink = `https://2gis.com.cy/cyprus/${
            searchStore.currentObject?.type === ResponseObjectTypes.branch
              ? "firm"
              : "geo"
          }/${data.data}/${mapglStore.currentMarkerCoords[0]}%2C${
            mapglStore.currentMarkerCoords[1]
          }`;

          console.log(mapglStore.currentMarkerCoords);
          const googleMapsLink = `https://www.google.com/maps/search/?api=1&query=${mapglStore.currentMarkerCoords[1]}%2C${mapglStore.currentMarkerCoords[0]}`;

          if (localStorageStore.shareCount) {
            SendData({
              type: PostMessageTypeEnum.SHARE_INFO,
              data: `For NaviCY:\n${twoGisLink}\n\nFor Google maps:\n${googleMapsLink}`,
            });
            localStorageStore.reduceCount(LocalStorageFieldsEnum.SHARE_COUNT);
          } else {
            SendData({
              type: PostMessageTypeEnum.SHARE_INFO,
              data: `For NaviCY:\n${twoGisLink}\n`,
            });
          }
          console.log(
            `For NaviCY:\n${twoGisLink}\n\nFor Google maps:\n${googleMapsLink}`
          );
        }
      } else {
        SendData(data);
      }
    };

    const getAddress = () => {
      return (
        searchStore.currentObject?.address &&
        searchStore.currentObject?.full_address_name?.split(",")[1] +
          "," +
          searchStore.currentObject?.full_address_name?.split(",")[2] +
          ", " +
          searchStore.currentObject.adm_div[
            searchStore.currentObject.adm_div.length - 1
          ].name +
          ", " +
          searchStore.currentObject.address?.postcode +
          ", " +
          searchStore.currentObject?.full_address_name?.split(",")[0]
      );
    };

    //TODO:: Remove later if something went wrong
    // const cardHeight = searchStore.currentObject?.floors || searchStore.currentObject?.links?.branches?.count ? "28%" : "24%"
    return (
      <>
        <Wrapper
          style={{
            display: searchStore.currentObject ? "flex" : "none",
            height: isOpen ? "95%" : "",
          }}
        >
          <Swipe
            onSwipeUp={() => {
              setIsOpen(canBeSwiped);
            }}
            onSwipeDown={() => {
              setIsOpen(false);
            }}
          >
            <IconButtonContainer
              onClick={() => {
                mapglStore.mapgl?.off("click", handleMapClick);
                setIsDirectionCard(true);
                searchStore.setSearchValue(searchInputRef.current?.value || "");
                if (
                  searchStore.currentObject?.point &&
                  mapglStore.currentMarkerCoords
                ) {
                  searchStore.currentObject.point.lat =
                    mapglStore.currentMarkerCoords[1];
                  searchStore.currentObject.point.lon =
                    mapglStore.currentMarkerCoords[0];
                  searchStore.prevSearchResults = [searchStore.currentObject];
                }
              }}
            >
              <IconButton
                style={{
                  top: isOpen ? "75vh" : "-35px",
                  transition: "top 0.3s",
                }}
              />
            </IconButtonContainer>
            {canBeSwiped ? <SwipeIndicator /> : null}
            <UpperInfo
              style={{
                flexDirection: isOpen ? "row" : "column",
                justifyContent: isOpen ? "space-around" : "",
                alignItems: isOpen ? "center" : "",
              }}
            >
              {isOpen ? (
                <ArrowDownIcon onClick={() => setIsOpen(false)} />
              ) : null}
              <PlaceTitle>
                {searchStore.currentObject?.name
                  ? searchStore.currentObject?.name
                  : "Utility structure"}
              </PlaceTitle>
              {!isOpen ? (
                <PlaceInfoWrapper>
                  <PlaceInfo>
                    {searchStore.currentObject?.subtype ||
                      searchStore.currentObject?.purpose_name ||
                      setType(searchStore.currentObject?.type)}
                  </PlaceInfo>
                  {!isOpen ? (
                    <ShareIcon
                      onClick={() =>
                        shareData({
                          type: PostMessageTypeEnum.SHARE_INFO,
                          data: searchStore.currentObject
                            ? searchStore.currentObject.id
                            : "",
                        })
                      }
                    />
                  ) : null}
                </PlaceInfoWrapper>
              ) : (
                <ShareIcon
                  onClick={() =>
                    shareData({
                      type: PostMessageTypeEnum.SHARE_INFO,
                      data: searchStore.currentObject
                        ? searchStore.currentObject.type ===
                          ResponseObjectTypes.building
                          ? searchStore.currentObject?.address.building_id
                          : searchStore.currentObject.id
                        : "",
                    })
                  }
                  style={{ marginLeft: "11px" }}
                />
              )}
            </UpperInfo>
            <Divider />
          </Swipe>
          {searchStore.currentObject?.type === ResponseObjectTypes.building ? (
            <BuildingCard
              getAddress={getAddress}
              shareData={shareData}
              isOpen={isOpen}
              searchStore={searchStore}
              mapglStore={mapglStore}
              coordinates={[
                searchStore.currentObject.point?.lon,
                searchStore.currentObject.point?.lat,
              ]}
              setCompaniesListIsOpen={setCompaniesListIsOpen}
            />
          ) : null}
          {searchStore.currentObject?.type === ResponseObjectTypes.admDiv ||
          searchStore.currentObject?.type === ResponseObjectTypes.street ? (
            <AdministrativeDivisionCard
              getAddress={getAddress}
              shareData={shareData}
              isOpen={isOpen}
              searchStore={searchStore}
              mapglStore={mapglStore}
              coordinates={[
                searchStore.currentObject.point?.lon,
                searchStore.currentObject.point?.lat,
              ]}
            />
          ) : null}
          {searchStore.currentObject?.type === ResponseObjectTypes.branch ? (
            <OrganizationCart
              getAddress={getAddress}
              shareData={shareData}
              setSwipeIsOpen={setIsOpen}
              mapglStore={mapglStore}
              searchStore={searchStore}
              isSwipeOpen={isOpen}
              setCompaniesListIsOpen={setCompaniesListIsOpen}
              coordinates={[
                searchStore.currentObject.point?.lon,
                searchStore.currentObject.point?.lat,
              ]}
            />
          ) : null}
        </Wrapper>
        {isCompaniesListOpen && (
          <CompaniesList setIsOpen={setCompaniesListIsOpen} />
        )}
      </>
    );
  }
);
