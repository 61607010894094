import React from "react";
import { RootStore } from "stores/RootStore";
import { StoreProvider } from "contexts/StoreContext";
import { Mapgl } from "components/Mapgl";
import { useApi } from "hooks/api";
import "./style.css";
import { HashRouter, Route, Switch } from "react-router-dom";
import { Settings } from "components/settings/Settings";
import { ManageSubscription } from "components/settings/ManageSubscription";
import { Subscription } from "components/settings/Subscription";
import { Contacts } from "components/settings/Contacts";

const StoreWrapper: React.FC = () => {
  const api = useApi();
  const store = new RootStore(api);

  return (
    <StoreProvider store={store}>
      <HashRouter>
        <Switch>
          <Route exact path="/settings">
            <Settings />
          </Route>
          <Route exact path="/settings/manage-subscription">
            <ManageSubscription />
          </Route>
          <Route exact path="/settings/subscription">
            <Subscription />
          </Route>
          <Route exact path="/settings/contacts">
            <Contacts />
          </Route>
          <Route path="/">
            <Mapgl />
          </Route>
        </Switch>
      </HashRouter>
    </StoreProvider>
  );
};

export default StoreWrapper;
