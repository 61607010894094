/* eslint-disable  */
import {
  useDirectionsStore,
  useLocalStorageStore,
  useMapglStore,
  useSearchStore,
} from "hooks/store";
import React, { useEffect, useRef, useState } from "react";
import mapglTypes from "@2gis/mapgl/types/index";
import { SearchInput } from "./SearchInput";
import { Results } from "./Results";
import styled from "styled-components";
import { ObjectCard } from "./ObjectsCard";
import { ResultItem } from "api/interfaces/search.interface";
import myLocation from "../assets/images/myLocation.svg";
import { DirectionInput } from "./DirectionInput";
import { DirectionCard } from "./DirectionCard";
import Plus from "../assets/images/zoom-plus.svg";
import Minus from "../assets/images/zoom-minus.svg";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { LocalStorageFieldsEnum } from "../enums/LocalStorageFieldsEnum.enum";
import { SendData } from "../utils/SendData";
import { PostMessageTypeEnum } from "../enums/PostMessageType.enum";

var parse = require("wellknown");

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Map = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;

const MyLocationButton = styled.div`
  width: 56px;
  height: 56px;
  border-radius: 100%;
  top: 55%;
  left: 83%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${myLocation});
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.1))
    drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.08));
  &:active {
    opacity: 0.5;
  }
`;

const CustomButtonsWrapper = styled.div`
  position: fixed;
  left: 83%;
  height: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ZoomContainer = styled.div`
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15))
    drop-shadow(4px 4px 6px rgba(0, 0, 0, 0.06));
`;

const ZoomPlusButton = styled.div`
  width: 46px;
  height: 46px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${Plus});
  border-radius: 10px 10px 0px 0px;
  &:active {
    opacity: 0.5;
  }
`;

const ZoomMinusButton = styled.div`
  width: 46px;
  height: 46px;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${Minus});
  border-radius: 0px 0px 10px 10px;
  margin-top: 4px;
  &:active {
    opacity: 0.5;
  }
`;

const MapWrapper = React.memo(
  () => {
    return <Map id="container"></Map>;
  },
  () => true
);

const usePrevious = (value: any) => {
  const ref = React.useRef();
  React.useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

const useLocationChange = (action: any) => {
  const location = useLocation();
  const prevLocation = usePrevious(location);
  React.useEffect(() => {
    action(location, prevLocation);
  }, [location]);
};

export const Mapgl = observer(() => {
  const [currentLocation, setCurrentLocation] = useState<string>();
  const history = useHistory();
  const mapglStore = useMapglStore();
  const searchStore = useSearchStore();
  const directionsStore = useDirectionsStore();
  const localStorageStore = useLocalStorageStore();
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [mapgl, setMapgl] = useState<mapglTypes.Map | null>(null);
  const [isObjectCardOpen, setIsObjectCardOpen] = useState(false);
  const [isEnterPressed, setIsEnterPressed] = useState(false);
  const [isDirectionCard, setIsDirectionCard] = useState(false);
  const [isResultsOpen, setIsResultsOpen] = useState(true);
  const [isMarkerCentered, setIsMarkerCentered] = useState(false);
  const [isShouldGoToMyLocation, setIsShouldGoToMyLocation] = useState(true);
  const [myLocationClickCount, setMyLocationClickCount] = useState(1);
  const [isShouldSetSavedState, setIsShouldSetSavedState] = useState(false);
  const [isFirstTimeRenderComplete, setFirstTimeRenderComplete] =
    useState(false);
  const [geolocation, setGeolocation] = useState<
    [number, number] | [null, null]
  >([null, null]);
  const createDirection = useRef<(() => void) | null>(null);

  const checkUserData = (event: any) => {
    if (event.data) {
      if (
        event.data === "trial" ||
        event.data === "basic" ||
        event.data === "premium" ||
        event.data === "inactive"
      ) {
        localStorageStore.setSubType(event);
      }
      if (typeof event.data === "object") {
        if (event.data.type === "counters") {
          localStorageStore.setCounters(event);
        } else {
          setGeolocation(event.data);
        }
      } else if (typeof event.data === "string") {
        if (event.data === "AFTER_CONNECTION_END") {
          setIsShouldGoToMyLocation(false);
          setIsShouldSetSavedState(true);
        } else {
          if (isDirectionCard) {
            setIsDirectionCard(false);
          }
          const id = event.data.split("/")[0];
          if (event.data.split("/").length > 1) {
            const coords = [
              parseFloat(event.data.split("/")[1].split(/%2C/)[0]),
              parseFloat(event.data.split("/")[1].split(/%2C/)[1]),
            ];
            mapgl && mapgl.setSelectedObjects([id]);
            searchStore.getObjectById(id).then((res: any) => {
              //TODO:: Refactor this
              searchStore.setCurrentObject(res?.result?.items[0]);
              if (searchStore.currentObject) {
                destroyAll();
                mapglStore.setCenter(coords as [number, number]);
                mapglStore.buildMarker(coords as [number, number], true);

                setIsObjectCardOpen(true);
              }
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", checkUserData);
    return () => window.removeEventListener("message", checkUserData);
  }, []);

  useEffect(() => {
    if (isFirstTimeRenderComplete) {
      localStorageStore.setLocalStorageItem(
        LocalStorageFieldsEnum.CURRENT_OBJECT,
        JSON.stringify(searchStore.currentObject)
      );
      localStorageStore.setLocalStorageItem(
        LocalStorageFieldsEnum.CURRENT_MARKER_COORDS,
        mapglStore.currentMarkerCoords
      );
      localStorageStore.setLocalStorageItem(
        LocalStorageFieldsEnum.IS_DIRECTION_CARD,
        isDirectionCard
      );
    }
    setFirstTimeRenderComplete(true);
  }, [searchStore.currentObject, isDirectionCard]);

  const openCardsFromPreviousState = (directionState: boolean) => {
    if (directionState) {
      setIsDirectionCard(true);
      if (createDirection.current) {
        createDirection.current();
      }
    } else {
      setIsObjectCardOpen(true);
    }
  };

  const setObjectFromLocalStorage = () => {
    const directionState = JSON.parse(
      localStorageStore.getLocalStorageItem(
        LocalStorageFieldsEnum.IS_DIRECTION_CARD
      )
    );
    const coordinates = localStorageStore
      .getLocalStorageItem(LocalStorageFieldsEnum.CURRENT_MARKER_COORDS)
      .split(",")
      .map((ele: string) => parseFloat(ele));
    mapglStore.buildMarker(coordinates, true);
    mapglStore.setCenter(coordinates);
    searchStore.setCurrentObject(
      JSON.parse(
        localStorageStore.getLocalStorageItem(
          LocalStorageFieldsEnum.CURRENT_OBJECT
        )
      )
    );
    openCardsFromPreviousState(directionState);
  };

  useEffect(() => {
    if (isShouldSetSavedState) {
      setObjectFromLocalStorage();
      setIsShouldSetSavedState(false);
    }
  }, [geolocation]);

  useLocationChange((location: any, prevLocation: any) => {
    if (history.action === "PUSH") {
    }

    if (history.action === "POP") {
      destroyAll();

      if (prevLocation?.pathname === "/direction") {
        showPreviousResults();
        onDirectionInputBack();
      } else if (prevLocation?.pathname === "/pin") {
        if (searchInputRef?.current?.value && searchStore.searchValue) {
          searchInputRef.current.value = searchStore.searchValue;
        }
        setIsObjectCardOpen(false);
        setIsResultsOpen(true);
      } else if (prevLocation?.pathname === "/results") {
        if (isObjectCardOpen) {
          setIsObjectCardOpen(false);
        }
        showPreviousResults();
        setIsResultsOpen(true);
      } else if (prevLocation?.pathname === "/map") {
        setIsObjectCardOpen(false);
        showPreviousResults();
      } else if (prevLocation?.pathname === "/") {
        setIsObjectCardOpen(false);
        setIsResultsOpen(false);
        destroyAll();
      } else {
        setIsObjectCardOpen(false);
        showPreviousResults();
      }
    }
  });

  const showPreviousResults = () => {
    destroyAll();
    searchStore.prevSearchResults?.map((item: any) => {
      if (item.point) {
        const marker = mapglStore.buildMarker([item.point.lon, item.point.lat]);
        if (
          mapglStore &&
          searchStore?.prevObject?.point &&
          item.point.lon === searchStore.prevObject.point.lon &&
          item.point.lat === searchStore.prevObject.point.lat
        ) {
          marker?.setIcon({ icon: "./red-marker.svg" });
        }
        marker?.on("click", () => {
          createMarkerEvent(marker, item);
        });
      }
    });

    searchStore.destroyPreviousResults();
  };

  useEffect((): any => {
    mapglStore.loadMapglAPI().then(() => {
      setMapgl(
        mapglStore.loadMapgl("container", {
          center: mapglStore.myLocation
            ? (mapglStore.myLocation as [number, number])
            : [33.345, 35.155],
          zoom: 13,
          key: process.env.REACT_APP_TWOGIS_KEY,
        })
      );
      if (mapglStore.myLocation) {
        mapglStore.setLocationMarker(mapglStore.myLocation as [number, number]);
      }
    });
    return () => mapgl && mapgl.destroy();
  }, []);

  const onGeolocationUpdate = () => {
    if (mapglStore.locationMarker) {
      mapglStore.locationMarker.destroy();
    }
    if (isMarkerCentered) {
      mapglStore.setCenter(geolocation as [number, number]);
    }
    mapglStore.setLocationMarker(geolocation as [number, number]);
  };

  useEffect(() => {
    if (geolocation[0] !== null) {
      if (isMarkerCentered) {
        onGeolocationUpdate();
      } else {
        if (mapglStore.locationMarker) {
          mapglStore.locationMarker.destroy();
        }
        mapglStore.setLocationMarker(geolocation);
      }
      if (isShouldGoToMyLocation) {
        goToMyLocation();
      }
    }
  }, [geolocation]);

  useEffect(() => {
    if (!isDirectionCard) {
      mapgl && mapgl.on("click", handleMapClick);
    } else {
      mapgl && mapgl.off("click", handleMapClick);
    }

    return () => {
      mapgl && mapgl.off("click", handleMapClick);
    };
  }, [mapgl, isDirectionCard]);

  useEffect(() => {
    mapgl && mapgl.on("zoomend", handleZoom);

    return () => {
      mapgl && mapgl.off("zoomend", handleZoom);
    };
  }, [mapgl]);

  useEffect(() => {
    mapgl && mapgl.on("moveend", handleZoom);
    return () => {
      mapgl && mapgl.off("moveend", handleZoom);
    };
  }, [mapgl]);

  const handleZoom = async (event: any) => {
    if (mapglStore.markers.length > 1) {
      // mapglStore.markers && mapglStore.destroyMarkers();
      const currentMarker = mapglStore.currentMarker?.getCoordinates();

      const viewPoints = mapgl?.getBounds();
      const query = searchInputRef.current?.value || searchStore.searchValue;

      const res = await searchStore.getSearchResultsByQuery(
        query as string,
        viewPoints?.northEast as [number, number],
        viewPoints?.southWest as [number, number]
      );

      const indexes: any[] = [];

      mapglStore.markers.map((marker: any, markerIndex) => {
        const markerPoint = marker.getCoordinates();
        const found = res.find((item: any) => {
          const itemPoint = [item.point?.lon, item.point?.lat];
          return (
            (itemPoint[0] === markerPoint[0] &&
              itemPoint[1] === markerPoint[1]) ||
            (currentMarker &&
              itemPoint[0] === currentMarker[0] &&
              itemPoint[1] === currentMarker[1])
          );
        });

        if (!found) {
          indexes.push(markerIndex);
        }
      });

      indexes.reverse().forEach((index: any) => {
        mapglStore.markers[index]?.destroy();
        mapglStore.markers.splice(index, 1);
      });

      const filterdRes: any[] = [];
      res.map((item: any, index: number) => {
        const itemPoint = [item.point?.lon, item.point?.lat];
        mapglStore.markers.find((marker: any) => {
          const markerPoint = marker.getCoordinates();
          if (
            markerPoint[0] == itemPoint[0] &&
            markerPoint[1] == itemPoint[1]
          ) {
            filterdRes.push(index);
          }
        });
      });

      filterdRes.reverse().forEach((index: any) => {
        res.splice(index, 1);
      });

      res.map((item: any) => {
        if (item.point) {
          const marker = mapglStore.buildMarker(
            [item.point.lon, item.point.lat],
            false,
            false
          );
          if (
            mapglStore &&
            mapglStore?.currentMarkerCoords &&
            item.point.lon === mapglStore?.currentMarkerCoords[0] &&
            item.point.lat === mapglStore?.currentMarkerCoords[1]
          ) {
            marker?.setIcon({ icon: "./red-marker.svg" });
          }
          marker?.on("click", () => {
            createMarkerEvent(marker, item);
          });
        }
      });

      setIsResultsOpen(false);
    }
  };

  const createMarkerEvent = (marker: mapglTypes.Marker, item: ResultItem) => {
    history.push("/pin");
    mapglStore.markers.map((marker: mapglTypes.Marker) => {
      marker.setIcon({ icon: "./marker.svg" });
    });
    mapglStore.setCurrentMarker(marker);
    marker.setIcon({ icon: "./red-marker.svg" });

    searchStore.getObjectById(item.id).then((res: any) => {
      searchStore.setCurrentObject(res.result?.items[0]);
    });

    setIsObjectCardOpen(true);
    mapglStore.setCurrentMarkerCoords([item.point.lon, item.point.lat]);
  };

  const destroyAll = () => {
    mapglStore.destroyPolygon();
    mapglStore.destroyMarkers();
    mapglStore.destroyDirection();
    mapgl && mapgl.setSelectedObjects([]);
  };

  const handleMapClick = (data: mapglTypes.MapPointerEvent) => {
    history.push("/map");

    if (!isDirectionCard) {
      searchInputRef && searchInputRef.current?.blur();
      destroyAll();
      searchStore.setCurrentObject(null);

      if (searchInputRef.current?.value) {
        searchInputRef.current.value = "";
      }

      mapglStore.buildMarker(data.lngLat);
      mapglStore.setCurrentMarkerCoords(data.lngLat);

      if (data.target?.id) {
        mapgl && mapgl.setSelectedObjects([data.target?.id]);
        searchStore.getObjectById(data.target?.id).then((res: any) => {
          res &&
            searchStore.setCurrentObject(res.result?.items[0] as ResultItem);
          setIsMarkerCentered(false);
          setIsObjectCardOpen(true);
        });
      } else {
        searchStore
          .getObjectByGeo(data?.lngLat as [number, number])
          .then((res: any) => {
            if (res?.data?.result?.items) {
              const parsedPolygon =
                res?.data?.result?.items[0]?.geometry?.selection &&
                parse(res?.data?.result?.items[0]?.geometry?.selection);
              searchStore.setCurrentObject(
                res?.data?.result?.items[0] as ResultItem
              );
              setIsMarkerCentered(false);
              setIsObjectCardOpen(true);

              if (typeof parsedPolygon?.coordinates[0] !== "number") {
                mapglStore.buildPolygon(parsedPolygon?.coordinates);
              }
            }
          });
      }
    }
  };

  const onDirectionInputBack = () => {
    mapglStore.destroyDirection();
    setIsObjectCardOpen(true);
    setIsDirectionCard(false);
  };

  const goToMyLocation = (): void => {
    mapglStore.locationMarker?.destroy();
    mapglStore.setLocationMarker(geolocation as [number, number]);
    mapgl?.setCenter(geolocation as [number, number]);

    if (isShouldGoToMyLocation && geolocation[0] !== null) {
      setIsShouldGoToMyLocation(false);
    }
  };

  const onClickLocationHandler = (): void => {
    setMyLocationClickCount(myLocationClickCount + 1);
    if (myLocationClickCount === 1 || myLocationClickCount === 2) {
      setTimeout(() => {
        if (myLocationClickCount === 1) {
          if (isMarkerCentered) {
            setIsMarkerCentered(false);
          } else {
            goToMyLocation();
          }
        } else {
          if (
            (!localStorageStore.routHandlerCount ||
              !parseInt(localStorageStore.routHandlerCount as any)) &&
            isDirectionCard
          ) {
            history.push("/settings/subscription");
          }
          setIsMarkerCentered(true);
        }
        setMyLocationClickCount(1);
      }, 500);
    }
  };

  const zoomPlus = () => {
    const currentZoom = mapgl?.getZoom();
    if (currentZoom) mapgl?.setZoom(currentZoom + 1);
  };

  const zoomMinus = () => {
    const currentZoom = mapgl?.getZoom();
    if (currentZoom && currentZoom > 1) mapgl?.setZoom(currentZoom - 1);
  };

  useEffect(() => {
    if (localStorageStore.routHandlerCount) {
      if (isMarkerCentered && isDirectionCard) {
        localStorageStore.countTime();
      }
      if (!isMarkerCentered || !isDirectionCard) {
        localStorageStore.stopTimer();
      }
    }
  }, [isMarkerCentered, isDirectionCard]);

  return (
    <Wrapper>
      {isDirectionCard ? (
        <DirectionInput
          searchStore={searchStore}
          onDirectionInputBack={onDirectionInputBack}
        />
      ) : (
        <SearchInput
          searchStore={searchStore}
          mapglStore={mapglStore}
          setOpenObjectCard={setIsObjectCardOpen}
          setIsEnterPressed={setIsEnterPressed}
          setIsResultsOpen={setIsResultsOpen}
          searchInputRef={searchInputRef}
          destroyAll={destroyAll}
          isDirectionCard={isDirectionCard}
          setIsMarkerCentred={setIsMarkerCentered}
        />
      )}

      {!isObjectCardOpen && !isDirectionCard && (
        <Results
          searchStore={searchStore}
          destroyAll={destroyAll}
          mapglStore={mapglStore}
          searchInputRef={searchInputRef}
          setIsMarkerCentred={setIsMarkerCentered}
          setOpenObjectCard={setIsObjectCardOpen}
          isEnterPressed={isEnterPressed}
          isResultsOpen={isResultsOpen}
          setIsResultsOpen={setIsResultsOpen}
          isDirectionCard={isDirectionCard}
          setIsEnterPressed={setIsEnterPressed}
        />
      )}
      {isObjectCardOpen && !isDirectionCard && (
        <ObjectCard
          searchStore={searchStore}
          searchInputRef={searchInputRef}
          mapglStore={mapglStore}
          localStorageStore={localStorageStore}
          setIsDirectionCard={setIsDirectionCard}
          handleMapClick={handleMapClick}
          currentLocation={currentLocation}
          setCurrentLocation={setCurrentLocation}
        />
      )}
      {isDirectionCard && (
        <DirectionCard
          currentGeolocation={geolocation}
          mapglStore={mapglStore}
          directionsStore={directionsStore}
          searchStore={searchStore}
          localStorageStore={localStorageStore}
          checkDirection={createDirection}
        />
      )}
      <MapWrapper />

      <CustomButtonsWrapper
        style={{
          top: isObjectCardOpen ? "27%" : "40%",
          transition: "top 0.3s",
        }}
      >
        <ZoomContainer>
          <ZoomPlusButton onClick={zoomPlus} />
          <ZoomMinusButton onClick={zoomMinus} />
        </ZoomContainer>
        <MyLocationButton
          style={isMarkerCentered ? { backgroundColor: "#23B064" } : {}}
          onClick={() => {
            if (geolocation[0]) {
              onClickLocationHandler();
            } else {
              SendData({
                type: PostMessageTypeEnum.NO_GEOLOCATION_ALERT,
                data: "",
              });
            }
          }}
        />
      </CustomButtonsWrapper>
    </Wrapper>
  );
});
