import { PostMessageTypeEnum } from "enums/PostMessageType.enum";
import { makeAutoObservable, observable } from "mobx";
import { SendData } from "utils/SendData";
import { getStorageItem, setStorageItem } from "../api/localStorage";
import { LocalStorageFieldsEnum } from "../enums/LocalStorageFieldsEnum.enum";
import { RootStore } from "./RootStore";

export interface Store {
  letsGoCount: number | null;
  shareCount: number | null;
  routHandlerCount: number | null;
  initialValue: number;
  isShareEnded: boolean;
  isLetsGoEnded: boolean;
  isRouteHandlerEnded: boolean;
  timeGeoInUse: number;
  timer: NodeJS.Timer | null;
}
export class LocalStorageStore implements Store {
  rootStore: RootStore;
  initialValue: number = 0;

  @observable letsGoCount: number = 0;
  @observable shareCount: number = 0;
  @observable routHandlerCount: number = 0;

  isLetsGoEnded: boolean = false;
  isRouteHandlerEnded: boolean = false;
  isShareEnded: boolean = false;
  timeGeoInUse: number = 0;
  timer: NodeJS.Timer | null = null;
  FREE_USAGE_TIME: number = 10;

  @observable subType: string | null = null;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this);
    this.letsGoCount = this.getLocalStorageItem(
      LocalStorageFieldsEnum.LETS_GO_COUNT
    );
    this.shareCount = this.getLocalStorageItem(
      LocalStorageFieldsEnum.SHARE_COUNT
    );
    this.routHandlerCount = this.getLocalStorageItem(
      LocalStorageFieldsEnum.ROUT_HANDLER_COUNT
    );
    this.subType = this.getLocalStorageItem(
      LocalStorageFieldsEnum.SUB_TYPE
    );
  }

  setSubType(event: any) {
    this.setLocalStorageItem(
      LocalStorageFieldsEnum.SUB_TYPE,
      event.data
    );
    this.subType = event.data;
  }

  setCounters(event: any) {
    this.setLocalStorageItem(
      LocalStorageFieldsEnum.LETS_GO_COUNT,
      event.data.letsGoCount
    );
    this.setLocalStorageItem(
      LocalStorageFieldsEnum.SHARE_COUNT,
      event.data.shareCount
    );
    this.setLocalStorageItem(
      LocalStorageFieldsEnum.ROUT_HANDLER_COUNT,
      event.data.routHandlerCount
    );
    this.letsGoCount = event.data.letsGoCount;
    this.shareCount = event.data.shareCount;
    this.routHandlerCount = event.data.routHandlerCount;
  }

  setLocalStorageItem(key: LocalStorageFieldsEnum, value: any): void {
    setStorageItem(key, value);
  }

  getLocalStorageItem(key: LocalStorageFieldsEnum): any {
    const value = getStorageItem(key);
    if (value === null) {
      this.setLocalStorageItem(key, this.initialValue);
    }
    return value;
  }

  //TODO:: Refactor this to array with objects
  reduceCount(key: LocalStorageFieldsEnum): void {
    if (key === LocalStorageFieldsEnum.SHARE_COUNT) {
      if (this.shareCount > 0) {
        this.shareCount -= 1;
        this.setLocalStorageItem(key, this.shareCount);
        SendData({
          type: PostMessageTypeEnum.SHARE_COUNT,
          data: this.shareCount.toString(),
        });
      } else {
        this.isShareEnded = true;
      }
    }
    if (key === LocalStorageFieldsEnum.LETS_GO_COUNT) {
      if (this.letsGoCount > 0) {
        this.letsGoCount -= 1;
        this.setLocalStorageItem(key, this.letsGoCount);
        SendData({
          type: PostMessageTypeEnum.LETS_GO_COUNT,
          data: this.letsGoCount.toString(),
        });
      } else {
        this.isLetsGoEnded = true;
      }
    }
    if (key === LocalStorageFieldsEnum.ROUT_HANDLER_COUNT) {
      if (this.routHandlerCount > 0) {
        this.routHandlerCount -= 1;
        this.setLocalStorageItem(key, this.routHandlerCount);
        SendData({
          type: PostMessageTypeEnum.ROUT_HANDLER_COUNT,
          data: this.routHandlerCount.toString(),
        });
      } else {
        this.isRouteHandlerEnded = true;
      }
    }
  }

  checkTime(): void {
    if (this.timeGeoInUse < this.FREE_USAGE_TIME) {
      this.timeGeoInUse += 1;
    } else {
      this.reduceCount(LocalStorageFieldsEnum.ROUT_HANDLER_COUNT);
      this.stopTimer();
    }
  }

  countTime(): void {
    this.timeGeoInUse = 0;
    this.timer = setInterval(() => this.checkTime(), 1000);
  }

  stopTimer(): void {
    this.timer && clearInterval(this.timer);
  }
}
