import { useContext } from "react";
import { StoreContext } from "contexts/StoreContext";
import { RootStore } from "stores/RootStore";

export const useRootStore = (): RootStore => useContext(StoreContext);

export const useMapglStore = () => {
  const { mapglStore } = useRootStore();
  return mapglStore;
};

export const useSearchStore = () => {
  const { searchStore } = useRootStore();
  return searchStore;
};

export const useDirectionsStore = () => {
  const { directionsStore } = useRootStore();
  return directionsStore;
};

export const useLocalStorageStore = () => {
  const { localStorageStore } = useRootStore();
  return localStorageStore;
}
