import { FC, useEffect, useState } from "react";
import { SearchStore } from "../stores/SearchStore";
import {
  AllBranchesContainer,
  ArrowDownIcon,
  ArrowRightIcon,
  BottomInfo,
  BottomText,
  BranchesCount,
  BranchesTitle,
  BuildingIcon,
  BuildingsIcon,
  CategoriesContainer,
  CategoriesItem,
  CategoriesItemText,
  CompanyContainer,
  Divider,
  EntranceIcon,
  FacebookIcon,
  GreenMarkerIcon,
  InstagramIcon,
  InternetIcon,
  PhoneIcon,
  SocialNetworkIconsContainer,
  Title,
  WorkTimeIcon,
} from "../styles/cardStyles";
import { MapglStore } from "../stores/MapglStore";
import { ContactTypes } from "../enums/ContactTypes.enum";
import { Contact, ResultItem } from "../api/interfaces/search.interface";
import { PostMessageData } from "../api/interfaces/postMassage.interface";
import { PostMessageTypeEnum } from "../enums/PostMessageType.enum";

var parse = require("wellknown");

interface OrganizationCartProps {
  searchStore: SearchStore;
  isSwipeOpen: boolean;
  setSwipeIsOpen: (arg0: boolean) => void;
  setCompaniesListIsOpen: (arg0: boolean) => void;
  mapglStore: MapglStore;
  shareData: (arg0: PostMessageData) => void;
  coordinates: [number, number];
  getAddress: () => string | undefined;
}

export const OrganizationCart: FC<OrganizationCartProps> = ({
  searchStore,
  isSwipeOpen,
  setCompaniesListIsOpen,
  mapglStore,
  setSwipeIsOpen,
  shareData,
  coordinates,
  getAddress,
}) => {
  let instagramLink = "";
  let facebookLink = "";
  const showEntrance = () => {
    let entrance = parse(
      searchStore.currentObject?.links?.entrances[0].geometry.points[0]
    ).coordinates;
    mapglStore.buildMarker(entrance);
    mapglStore.setCenter(entrance);
    mapglStore.setZoom(18);
    setSwipeIsOpen(false);
  };

  const contactTypeChecker = (contactType: ContactTypes) => {
    if (searchStore.currentObject?.contact_groups)
      return searchStore.currentObject?.contact_groups[0].contacts.some(
        (item) => item.type === contactType
      );
  };

  const Contacts = (contactType: ContactTypes) => {
    //TODO:: Refactor this function
    let arr: Contact[] = [];
    if (!!searchStore.currentObject?.contact_groups) {
      searchStore.currentObject?.contact_groups.forEach((item) =>
        item.contacts.forEach((contact) => {
          if (contact.type === ContactTypes.Instagram) {
            instagramLink = contact.url;
          }
          if (contact.type === ContactTypes.Facebook) {
            facebookLink = contact.url;
          }
          arr.push(contact);
        })
      );
      return arr.map((item: any, index) => {
        if (item.type === contactType) {
          if (item.type === ContactTypes.Website) {
            return (
              <BottomText
                onClick={() =>
                  shareData({ type: PostMessageTypeEnum.URL, data: item.url })
                }
                key={item.value}
              >{`${item.text}`}</BottomText>
            );
          }
          return <BottomText key={item.value}>{`${item.text}`}</BottomText>;
        }

        //TODO refactor
        return <div key={item.value} />;
      });
    }
  };

  const openCompanyCard = async (id: string) => {
    await searchStore.getObjectById(id).then((res: any) => {
      res && searchStore.setCurrentObject(res.result?.items[0] as ResultItem);
      setSwipeIsOpen(false);
    });

    if (searchStore.currentObject) {
      mapglStore.destroyMarkers();
      mapglStore.buildMarker([
        searchStore.currentObject?.point.lon,
        searchStore.currentObject?.point.lat,
      ]);
      mapglStore.setCenter([
        searchStore.currentObject?.point.lon,
        searchStore.currentObject?.point.lat,
      ]);
    }
  };

  const [workingTime, setWorkingTime] = useState<any>([]);

  const getCurrentDay = () => {
    let days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    let d = new Date();
    let n = d.getDay();

    searchStore.currentObject?.schedule &&
      setWorkingTime([
        searchStore.currentObject?.schedule[days[n]]?.working_hours[0]?.from,
        searchStore.currentObject?.schedule[days[n]]?.working_hours[0]?.to,
      ]);
  };

  useEffect(() => {
    getCurrentDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStore.currentObject?.schedule]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: isSwipeOpen ? "scroll" : "hidden",
      }}
    >
      <BottomInfo>
        <GreenMarkerIcon onClick={() => mapglStore.setCenter(coordinates)} />
        <BottomText
          style={{ width: "85%" }}
          onClick={() => mapglStore.setCenter(coordinates)}
        >
          {getAddress()}
        </BottomText>
      </BottomInfo>
      {!!searchStore.currentObject?.links?.branches?.count ? (
        <BottomInfo>
          <BuildingIcon />
          <BottomText>
            {(searchStore.currentObject?.floors &&
              searchStore.currentObject?.links?.branches?.count &&
              `${searchStore.currentObject?.floors.ground_count} floor | ${searchStore.currentObject?.links?.branches?.count} companies`) ||
              (searchStore.currentObject?.floors &&
                `${searchStore.currentObject?.floors.ground_count} floor`) ||
              (searchStore.currentObject?.links?.branches?.count &&
                `${searchStore.currentObject?.links?.branches?.count} companies`)}
          </BottomText>
        </BottomInfo>
      ) : null}
      {isSwipeOpen ? (
        <>
          {searchStore.currentObject?.links?.entrances ? (
            <BottomInfo onClick={() => showEntrance()}>
              <EntranceIcon />
              <BottomText
                style={{
                  color: "#23B064",
                }}
              >
                Show entrance on map
              </BottomText>
            </BottomInfo>
          ) : null}
          {searchStore.currentObject?.schedule ? (
            <BottomInfo>
              <WorkTimeIcon />
              {workingTime[0] ? (
                <BottomText>{`Today ${workingTime[0]} — 
                        ${workingTime[1]} Open`}</BottomText>
              ) : (
                <BottomText>Closed</BottomText>
              )}
            </BottomInfo>
          ) : null}
          {contactTypeChecker(ContactTypes.Phone) ? (
            <BottomInfo>
              <PhoneIcon />
              <div
                style={{
                  width: "100%",
                }}
              >
                {Contacts(ContactTypes.Phone)}
              </div>
            </BottomInfo>
          ) : null}
          {contactTypeChecker(ContactTypes.Website) ? (
            <BottomInfo>
              <InternetIcon />
              <div>
                {Contacts(ContactTypes.Website)}
                <SocialNetworkIconsContainer>
                  {contactTypeChecker(ContactTypes.Instagram) ? (
                    <InstagramIcon
                      onClick={() =>
                        shareData({
                          type: PostMessageTypeEnum.URL,
                          data: instagramLink,
                        })
                      }
                    />
                  ) : null}
                  {contactTypeChecker(ContactTypes.Facebook) ? (
                    <FacebookIcon
                      onClick={() =>
                        shareData({
                          type: PostMessageTypeEnum.URL,
                          data: facebookLink,
                        })
                      }
                    />
                  ) : null}
                </SocialNetworkIconsContainer>
              </div>
            </BottomInfo>
          ) : null}
          {parseInt(searchStore.currentObject?.org?.branch_count as string) >
          1 ? (
            <div>
              <Divider
                style={{
                  height: "2px",
                }}
              />
              <Title>other branches</Title>
              <BottomInfo
                style={{
                  width: "100%",
                  borderBottom: "solid 1px rgba(33, 33, 33, 0.08)",
                  margin: "18px 0 6px 0",
                }}
              >
                <BuildingsIcon />
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  {searchStore.branchesInOrganization?.result.items.map(
                    (item: any) => {
                      return (
                        <CompanyContainer
                          key={item.id}
                          onClick={() => {
                            openCompanyCard(item.id);
                          }}
                        >
                          <BottomText>{`${item.full_address_name}, ${item.address.postcode}`}</BottomText>
                          <ArrowRightIcon />
                        </CompanyContainer>
                      );
                    }
                  )}
                </div>
              </BottomInfo>
            </div>
          ) : null}
          {!!searchStore.currentObject?.links?.branches ? (
            <>
              <AllBranchesContainer
                onClick={() => {
                  setCompaniesListIsOpen(true);
                  setSwipeIsOpen(false);
                }}
              >
                <BranchesTitle>All companies</BranchesTitle>
                <BranchesCount>
                  <div
                    style={{
                      margin: "0 8px",
                    }}
                  >
                    {searchStore.currentObject?.links?.branches?.count}
                  </div>
                  <ArrowDownIcon />
                </BranchesCount>
              </AllBranchesContainer>
            </>
          ) : null}
          <Title>categories</Title>
          {searchStore.currentObject?.rubrics ? (
            <CategoriesContainer>
              {searchStore.currentObject.rubrics.map((item) => {
                return (
                  <CategoriesItem key={item.id}>
                    <CategoriesItemText>{item.name}</CategoriesItemText>
                  </CategoriesItem>
                );
              })}
            </CategoriesContainer>
          ) : null}
        </>
      ) : null}
    </div>
  );
};
