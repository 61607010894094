/* eslint-disable no-mixed-operators */
import React, { FC } from "react";
import { MapglStore } from "stores/MapglStore";
import { SearchStore } from "../stores/SearchStore";
import {
  AdditionalInfo,
  ArrowRightIcon,
  BottomInfo,
  BottomText,
  BuildingIcon,
  BuildingsIcon,
  Divider,
  GreenMarkerIcon,
  ShareIcon,
  Title,
} from "../styles/cardStyles";
import { PostMessageData } from "../api/interfaces/postMassage.interface";
import { PostMessageTypeEnum } from "../enums/PostMessageType.enum";

interface BuildingCardProps {
  isOpen: boolean;
  searchStore: SearchStore;
  setCompaniesListIsOpen: (arg0: boolean) => void;
  shareData: (arg0: PostMessageData) => void;
  mapglStore: MapglStore;
  coordinates: [number, number];
  getAddress: () => string | undefined;
}

export const BuildingCard: FC<BuildingCardProps> = ({
  isOpen,
  searchStore,
  setCompaniesListIsOpen,
  shareData,
  mapglStore,
  coordinates,
  getAddress,
}) => {
  return (
    <>
      {searchStore.currentObject?.address ? (
        <BottomInfo>
          <GreenMarkerIcon onClick={() => mapglStore.setCenter(coordinates)} />
          <BottomText onClick={() => mapglStore.setCenter(coordinates)}>
            {getAddress()}
          </BottomText>
        </BottomInfo>
      ) : (
        <BottomInfo>
          <GreenMarkerIcon />
          <BottomText>{searchStore.currentObject?.full_name}</BottomText>
          {!isOpen ? (
            <ShareIcon
              onClick={() =>
                shareData({
                  type: PostMessageTypeEnum.SHARE_INFO,
                  data: searchStore.currentObject
                    ? searchStore.currentObject?.address.building_id
                    : "",
                })
              }
            />
          ) : null}
        </BottomInfo>
      )}
      {!!searchStore.currentObject?.links?.branches?.count ? (
        <BottomInfo>
          <BuildingIcon />
          <BottomText>
            {(searchStore.currentObject?.floors &&
              searchStore.currentObject?.links?.branches?.count &&
              `${searchStore.currentObject?.floors.ground_count} floor | ${searchStore.currentObject?.links?.branches?.count} companies`) ||
              (searchStore.currentObject?.floors &&
                `${searchStore.currentObject?.floors.ground_count} floor`) ||
              (searchStore.currentObject?.links?.branches?.count &&
                `${searchStore.currentObject?.links?.branches?.count} companies`)}
          </BottomText>
        </BottomInfo>
      ) : null}
      {searchStore.currentObject?.links?.branches?.count && isOpen ? (
        <div>
          <Divider
            style={{
              height: "2px",
            }}
          />
          <Title>Сompanies</Title>
          <BottomInfo
            onClick={() => setCompaniesListIsOpen(true)}
            style={{
              height: "72px",
              width: "100%",
              borderBottom: "solid 1px rgba(33, 33, 33, 0.08)",
              alignItems: "center",
              margin: "18px 0",
            }}
          >
            <BuildingsIcon />
            <div
              style={{
                width: "360px",
              }}
            >
              <BottomText>All Companies</BottomText>
              <AdditionalInfo>{`${searchStore.currentObject?.links?.branches?.count} companies`}</AdditionalInfo>
            </div>
            <ArrowRightIcon />
          </BottomInfo>
        </div>
      ) : null}
    </>
  );
};
