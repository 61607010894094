/* eslint-disable  */
import Marker from "assets/images/black-marker.svg";
import Building from "assets/images/building.svg";
import Branch from "assets/images/branch.svg";
import Rubric from "assets/images/rubric.svg";
import GeoObject from "assets/images/geo-object.svg";
import { ResponseObjectTypes } from "enums/ResponseObjectTypes.enum";
import { observer } from "mobx-react";
import { SearchStore } from "stores/SearchStore";
import styled from "styled-components";
import { MapglStore } from "stores/MapglStore";
import { ResultItem } from "api/interfaces/search.interface";
import { useEffect } from "react";
import { ID_TYPES } from "api/search";
import mapglTypes from "@2gis/mapgl/types/index";
import { useHistory } from "react-router-dom";

const Wrapper = styled.div`
  flex-direction: column;
  background: #ffffff;
  position: absolute;
  top: 120px;
  z-index: 60;
  width: 100%;
  height: 100%;
  border-radius: 20px 20px 0px 0px;
  overflow: scroll;
`;

const ItemWrapper = styled.div`
  min-height: 72px;
  display: flex;
  align-items: center;
  border-top: 3px solid #fafafafa;
`;

const ResultIcon = styled.p`
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 22px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ItemName = styled.div`
  margin-left: 29px;
`;

const ItemDescription = styled.div`
  margin-left: 29px;
  color: rgba(0, 0, 0, 0.6);
  line-height: 20px;
  letter-spacing: 0.25px;
  font-size: 14px;
`;

const NotFoundWrapper = styled.div`
  margin-left: 16px;
  margin-top: 39px;
`;

const NotFoundTitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #000000;
`;

const NotFoundDescription = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #000000;
`;

interface ResultsProps {
  searchStore: SearchStore;
  mapglStore: MapglStore;
  setOpenObjectCard: (value: boolean) => void;
  searchInputRef: React.RefObject<HTMLInputElement>;
  isEnterPressed: boolean;
  destroyAll: () => void;
  isResultsOpen: boolean;
  setIsResultsOpen: (val: boolean) => void;
  isDirectionCard: boolean;
  setIsMarkerCentred: (val: boolean) => void;
  setIsEnterPressed: (value: boolean) => void;
}

export const Results = observer(
  ({
    searchStore,
    setOpenObjectCard,
    mapglStore,
    searchInputRef,
    isEnterPressed,
    destroyAll,
    isResultsOpen,
    setIsResultsOpen,
    isDirectionCard,
    setIsMarkerCentred,
    setIsEnterPressed,
  }: ResultsProps) => {
    const history = useHistory();

    const getIcon = (type: ResponseObjectTypes) => {
      if (
        type === ResponseObjectTypes.branch ||
        type === ResponseObjectTypes.organization
      ) {
        return Branch;
      } else if (
        type === ResponseObjectTypes.admDiv ||
        type === ResponseObjectTypes.admDivCity ||
        type === ResponseObjectTypes.admDivCountry ||
        type === ResponseObjectTypes.admDivDistrict ||
        type === ResponseObjectTypes.admDivDistrictArea ||
        type === ResponseObjectTypes.admDivPlace ||
        type === ResponseObjectTypes.admDivRegion ||
        type === ResponseObjectTypes.admDivSettlement
      ) {
        return Marker;
      } else if (type === ResponseObjectTypes.building) {
        return Building;
      } else if (
        type === ResponseObjectTypes.metarubric ||
        type === ResponseObjectTypes.rubric
      ) {
        return Rubric;
      } else {
        return GeoObject;
      }
    };

    const handleClick = async (item: any) => {
      if (searchInputRef.current) {
        searchInputRef.current.value = item.name;
      }

      if (
        item.type === "rubric" ||
        item.type === "metarubric" ||
        item.type === "org"
      ) {
        const viewPoints = mapglStore.mapgl?.getBounds();

        const res = await searchStore.getSearchResultsByQuery(
          searchInputRef.current?.value as string,
          viewPoints?.northEast as [number, number],
          viewPoints?.southWest as [number, number]
        );

        res.map((item: any) => {
          if (item.point) {
            const marker = mapglStore.buildMarker(
              [item.point.lon, item.point.lat],
              false,
              false
            );
            marker?.on("click", () => {
              createMarkerEvent(marker, item);
            });
          }
        });
        setIsResultsOpen(false);
        // TODO fix types
      } else {
        const res: any = await searchStore.getObjectById(item.id);
        const resultItem: ResultItem = res.result?.items[0];

        searchStore.setCurrentObject(resultItem);
        mapglStore.buildMarker(
          [resultItem.point.lon, resultItem.point.lat],
          true
        );
        mapglStore.setCenter([resultItem.point.lon, resultItem.point.lat]);
        setOpenObjectCard(true);
      }
    };

    const createMarkerEvent = (marker: mapglTypes.Marker, item: ResultItem) => {
      mapglStore.markers.map((marker: mapglTypes.Marker) => {
        marker.setIcon({ icon: "./marker.svg" });
      });
      mapglStore.setCurrentMarker(marker);
      marker.setIcon({ icon: "./red-marker.svg" });

      searchStore.getObjectById(item.id).then((res: any) => {
        searchStore.setCurrentObject(res.result?.items[0]);
      });

      setOpenObjectCard(true);
      mapglStore.setCurrentMarkerCoords([item.point.lon, item.point.lat]);
      mapglStore.setCenter([item.point.lon, item.point.lat]);
      history.push("/pin");
    };

    useEffect(() => {
      if (isEnterPressed && searchStore.searchResults.length) {
        searchStore.searchResults.map((item: ResultItem) => {
          const marker =
            item?.point &&
            mapglStore.buildMarker([item.point.lon, item.point.lat]);

          marker?.on("click", () => {
            createMarkerEvent(marker, item);
          });
        });
        setIsResultsOpen(false);
        searchInputRef.current?.blur();
      }
    }, [isEnterPressed]);

    useEffect(() => {
      if (!isResultsOpen && searchInputRef.current?.onfocus) {
        searchInputRef.current.onfocus = () => {
          !isResultsOpen && setIsResultsOpen(true);
        };
      }
    }, [isResultsOpen, searchInputRef]);

    const getDescription = (item: any) => {
      if (item.type === "org") {
        return "organization";
      } else if (item.type === "branch") {
        return (
          item?.type +
            ", " +
            (item?.adm_div?.length &&
              item?.adm_div[item.adm_div?.length - 1].name) +
            ", " +
            item?.adm_div[item.adm_div?.length - 2].name || item?.full_name
        );
      } else if (item.type === "rubric") {
        return item.type;
      } else {
        return (
          item?.type +
            ", " +
            (item?.adm_div?.length &&
              item?.adm_div[item.adm_div?.length - 1].name) || item?.full_name
        );
      }
    };
    return (
      <>
        {isResultsOpen && (
          <Wrapper
            style={{
              display: searchInputRef?.current?.value ? "flex" : "none",
            }}
          >
            {searchStore.suggestionsResults.map((item: any, index) => {
              return (
                <ItemWrapper
                  key={item.id}
                  onClick={() => {
                    history.push("/results");
                    setIsMarkerCentred(false);
                    destroyAll();
                    handleClick(item);
                  }}
                  style={{
                    marginBottom:
                      searchStore.suggestionsResults.length - 1 === index
                        ? "200px"
                        : "0",
                  }}
                >
                  <ResultIcon
                    style={{
                      backgroundImage: `url(${getIcon(item.type)})`,
                    }}
                  ></ResultIcon>
                  <TextWrapper>
                    <ItemName>{item?.name}</ItemName>
                    <ItemDescription>{getDescription(item)}</ItemDescription>
                  </TextWrapper>
                </ItemWrapper>
              );
            })}
            {isEnterPressed && (
              //  && searchStore.searchResults?.meta.code === 404 &&
              <NotFoundWrapper>
                <NotFoundTitle>No Results Found</NotFoundTitle>
                <NotFoundDescription>
                  Try searching for something else, check for typos or rephrase
                  it
                </NotFoundDescription>
              </NotFoundWrapper>
            )}
          </Wrapper>
        )}
      </>
    );
  }
);
