import styled from "styled-components";
import BackArrow from "assets/images/back-arrow.svg";
import Location from "assets/images/location.svg";
import GreenMarker from "assets/images/green-marker.svg";
import { SearchStore } from "stores/SearchStore";
import { observer } from "mobx-react";

const DirectionInputWrapper = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: fit-content;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const StartPointWrapper = styled.div`
  display: flex;
`;

const BackArrowIcon = styled.div`
  background-image: url(${BackArrow});
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  margin-left: 10px;
  margin-top: 20px;
`;

const Input = styled.input`
  height: 24px;
  width: 190px;
  border: 0;
  margin-left: 19px;
`;

const LocationIcon = styled.div`
  background-image: url(${Location});
  width: 24px;
  height: 24px;
`;

const LocationInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 16px;
  margin-top: 10px;
  max-height: 40px;
`;

const Line = styled.div`
  position: absolute;
  top: 44px;
  left: 90px;
  height: 1px;
  width: 100%;
  background: rgba(33, 33, 33, 0.08);
`;

const EndPointWrapper = styled.div`
  margin-top: 4%;
  margin-bottom: 3%;
  margin-left: 44px;
  display: flex;
`;

const GreenMarkerIcon = styled.p`
  background-image: url(${GreenMarker});
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
  background-position: center;
`;

const EndPoint = styled.div`
  margin-left: 19px;
`;

interface DirectionInputProps {
  searchStore: SearchStore;
  onDirectionInputBack: () => void;
}

export const DirectionInput = observer(
  ({ searchStore, onDirectionInputBack }: DirectionInputProps) => {
    const backHandler = () => {
      onDirectionInputBack();
    };

    return (
      <DirectionInputWrapper>
        <StartPointWrapper>
          <BackArrowIcon onClick={backHandler} />
          <LocationInputWrapper>
            <LocationIcon />
            <Input placeholder="Your location" />
          </LocationInputWrapper>
        </StartPointWrapper>
        <Line />
        <EndPointWrapper>
          <GreenMarkerIcon />
          <EndPoint>
            {(searchStore.currentObject?.address_name &&
              searchStore.currentObject.address?.postcode &&
              searchStore.currentObject.address_name +
                ", " +
                searchStore.currentObject.address?.postcode) ||
              searchStore.currentObject?.name ||
              searchStore.currentObject?.full_name}
          </EndPoint>
        </EndPointWrapper>
      </DirectionInputWrapper>
    );
  }
);
